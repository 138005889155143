// vue-final-modal doc
// https://vue-final-modal.org/api/

import { $vfm } from 'vue-final-modal';
import DeputyModalTemplate from './../components/deputy-modal-template';
import Vue from 'vue';
import { mapState } from 'vuex';

const modalTargetToComponentMap = {
  '#signupModal': 'signup-form',
  '#bookADemo': 'book-a-consultation',
  '#generalExitPopup': 'general-exit-pop-up-modal',
  '#blogExitPopup': 'blog-exit-pop-up-modal',
  '#newsletterModal': 'modal-newsletter',
};

export default {
  computed: {
    ...mapState({
      signupModalData: state => state.modal.signupModalData,
    }),
  },
  mounted() {
    this.$root.$on('open-deputy-modal', data => {
      // add the id for analytics as actionSource
      if (data.id && this.$store) {
        this.$store.commit('analytics/setActionSource', data.id);
      }

      if (this.$store) {
        this.$store.commit('analytics/viewOpened', data.modalTarget);
      }

      const exitPopupIds = new Set(['#generalExitPopup', '#blogExitPopup']);

      Vue.component(modalTargetToComponentMap[data.modalTarget], () =>
        import(`../components/${modalTargetToComponentMap[data.modalTarget]}`)
      );
      $vfm.show({
        component: DeputyModalTemplate,
        bind: { color: data.color },
        slots: {
          default: {
            component: modalTargetToComponentMap[data.modalTarget],
            on: {
              'click-cta': () => {
                if (exitPopupIds.has(data.modalTarget) && this.$store) {
                  this.$store.commit(
                    'analytics/submitClicked',
                    data.modalTarget
                  );
                }
              },
            },
          },
        },
        on: {
          'before-close': () => {
            if (exitPopupIds.has(data.modalTarget) && this.$store) {
              this.$store.commit('analytics/viewClosed', data.modalTarget);
            }
          },
        },
      });
    });
    // we can trigger the modal by a query parameter in the url
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('book') && urlParams.get('book') === 'consult') {
      this.$root.$emit('open-deputy-modal', {
        modalTarget: '#bookADemo',
      });
    }

    if (urlParams.has('signup') && urlParams.get('signup') === 'open') {
      this.$root.$emit('open-deputy-modal', {
        modalTarget: '#signupModal',
      });
    }
  },
  methods: {
    anyModalIsOpen() {
      return $vfm.openedModals.length > 0;
    },
  },
};
