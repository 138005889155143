export const state = () => ({
  formId: null,
  social: null,
  submitForm: false,
  errors: {
    email: {
      isInvalid: false,
      text: 'Please enter a valid email',
    },
    name: {
      isInvalid: false,
      text: 'Please enter your name',
    },
    terms: {
      isInvalid: false,
      text: 'Please read and accept our legal terms to continue',
    },
    generic: {
      isInvalid: false,
      text: 'Sorry, there was a problem creating your account. Please try again or contact support if the issue continues. [DP001]',
    },
    recaptcha: {
      isInvalid: false,
      text: 'Sorry, there was a problem creating your account. Please try again or contact support if the issue continues. [DP002]',
    },
    api: {
      isInvalid: false,
      text: 'Sorry, there was a problem creating your account. Please try again or contact support if the issue continues. [DP003]',
    },
    talon: {
      isInvalid: false,
      text: '',
    },
  },
  marketoSignupFormId: '1540',
  marketoForms: [],
});

export const mutations = {
  // Form
  setFormId(state, payload) {
    state.formId = payload;
  },
  setSocial(state, payload) {
    state.social = payload;
  },
  submitForm(state, payload) {
    state.submitForm = payload;
  },
  showError(state, payload) {
    state.errors[payload].isInvalid = true;
  },
  hideError(state, payload) {
    state.errors[payload].isInvalid = false;
  },
  saveForm(state, payload) {
    Object.assign(state, payload);
  },
  formLoaded(state, payload) {
    state.marketoForms.push(payload);
  },
};

export const getters = {
  isMarketoFormLoaded: state => id => {
    return state.marketoForms.includes(id);
  },
};
