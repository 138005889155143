export default function parseUrlRegion(url) {
  if (!url) {
    return '';
  }

  if (url.match(/^us\//)) {
    return url.substring(2);
  }

  return `/${url}`;
}
