// Gets the Google Analytics clientId from the GA Tag
export default async function () {
  if (process.client) {
    let counter = 0;
    const checkIfGAIsLoaded = setInterval(async () => {
      if (counter <= 200) {
        if (typeof window.ga !== 'undefined') {
          window.ga(function () {
            const allGaTags = window.ga.getAll();
            if (allGaTags.length > 0) {
              window.GAClientId = allGaTags[0].get('clientId');
            }
          });
          clearInterval(checkIfGAIsLoaded);
        }
        counter++;
      } else {
        clearInterval(checkIfGAIsLoaded);
      }
    }, 5);
  }
}
