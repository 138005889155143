export default {
  computed: {
    isHideVendorWidgetsPage() {
      return (
        document.location.pathname.includes('terms/legal') ||
        document.location.pathname.includes('login/welcome-carousel')
      );
    },
  },
  beforeMount() {
    if (this.isHideVendorWidgetsPage && typeof airgap !== 'undefined') {
      airgap.loadOptions.prompt = '0';
    }
  },
};
