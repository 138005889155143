const prodSiteKey = '6LdYwnYkAAAAAG4gtFY6L5akvLdBi47osIuIj1lt';

/**
 * Returns the recaptcha site key.
 * @see: https://deputy.atlassian.net/wiki/spaces/W/pages/2894528535/reCAPTCHA+on+Signup
 *
 * @return {string} The siteKey
 */
export function getRecaptchaSiteKey() {
  return prodSiteKey;
}

/**
 * Returns the recaptcha token for security check.
 * @see https://cloud.google.com/recaptcha-enterprise/docs/instrument-web-pages
 *
 * @param {string} siteKey The Google Recaptcha Enterprise Site Key
 * @return {Promise|boolean} Token value
 */
export async function getRecaptchaToken(siteKey) {
  if (typeof grecaptcha === undefined) {
    console.error('grecaptcha is undefined');
    return false;
  } else {
    return new Promise(async resolve => {
      await grecaptcha.enterprise.ready(async function () {
        try {
          const token = await grecaptcha.enterprise.execute(siteKey, {
            action: 'SIGNUP',
          });
          resolve(token);
        } catch (e) {
          console.error('recaptcha:', e);
          resolve(false);
        }
      });
    });
  }
}
