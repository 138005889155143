const signupData = () => import('../lib/storyblok-local-json/signup.json');

export const state = () => ({
  videoPlay: [],
  modalContent: {
    bookAConsultation: {},
    signup: {},
    newsletter: {
      headline: 'Never miss a beat',
      subline:
        'More than ${workplaces} workplaces have used Deputy. Subscribe to learn why.',
      ctaText: 'Subscribe now',
      formID: '1261',
    },
  },
  modalData: {},
  blogModalData: {},
  signupModalData: {},
});

export const mutations = {
  SET_BOOK_A_CONSULTATION_CONTENT(state, payload) {
    state.modalContent.bookAConsultation = payload;
  },

  SET_SIGNUP_CONTENT(state, payload) {
    state.modalContent.signup = payload;
  },

  SET_NEWSLETTER_CONTENT(state, payload) {
    state.modalContent.newsletter = payload;
  },

  SET_VIDEO_PLAY(state, payload) {
    state.videoPlay.push(payload);
  },

  SET_VIDEO_CLOSE(state) {
    state.videoPlay.pop();
  },

  SET_MODAL_DATA(state, payload) {
    state.modalData = payload;
  },

  SET_BLOG_MODAL_DATA(state, payload) {
    state.blogModalData = payload;
  },

  SET_SIGNUP_MODAL_DATA(state, payload) {
    state.signupModalData = payload;
  },
};

export const actions = {
  async setModalData({ commit, rootState }) {
    const modalData = () =>
      import(
        `../lib/storyblok-local-json/general-exit-pop-up-${rootState.region}.json`
      );
    modalData().then(data => {
      commit('SET_MODAL_DATA', data.content.content[0]);
    });
  },
  async setBlogModalData({ commit, rootState }) {
    const blogModalData = () =>
      import(
        `../lib/storyblok-local-json/blog-exit-pop-up-${rootState.region}.json`
      );
    blogModalData().then(data => {
      commit('SET_BLOG_MODAL_DATA', data.content);
    });
  },
  async setSignupData({ commit }) {
    signupData().then(data => {
      commit('SET_SIGNUP_MODAL_DATA', data.content.content[0]);
    });
  },
  async fetchBookAConsultation(
    { commit, state },
    { $storyapi, region, version }
  ) {
    const storyUrl = `cdn/stories/_content_types/modal/book-a-consultation`;
    const resolveRelations = `book-a-consultation.quote_${region}`;

    const storyParams = {
      version,
      resolve_relations: resolveRelations,
    };

    try {
      // const bookAConsultationData = await $storyapi.get(storyUrl, storyParams);

      // const payload = bookAConsultationData.data.story.content.content[0];
      const payload = {}; // bookAConsultationData.story.content.content[0];
      commit('SET_BOOK_A_CONSULTATION_CONTENT', payload);
    } catch (e) {
      console.error(
        'There was an error fetching the book a consultation modal:',
        e
      );
      console.error('isPreview: ', process.env.isPreview);
      console.error('storyUrl: ', storyUrl);
      console.error('storyParams: ', storyParams);
    }
  },
  async fetchSignup({ commit, state }, { $storyapi, version }) {
    const storyUrl = `cdn/stories/_content_types/modal/signup`;

    const storyParams = {
      version,
    };

    try {
      // const signupData = await $storyapi.get(storyUrl, storyParams);

      // const payload = signupData.data.story.content.content[0];
      const payload = {}; // signupData.story.content.content[0];
      commit('SET_SIGNUP_CONTENT', payload);
    } catch (e) {
      console.error('There was an error fetching the signup modal:', e);
      console.error('isPreview: ', process.env.isPreview);
      console.error('storyUrl: ', storyUrl);
      console.error('storyParams: ', storyParams);
    }
  },
};
