export default {
  data() {
    return {
      slider: null,
      sliderItems: null,
      prev: null,
      next: null,
      allowShift: true,
      posX1: 0,
      posX2: 0,
      posInitial: null,
      posFinal: null,
      threshold: 100,
      slides: null,
      slidesLength: 0,
      index: 0,
      slideSize: 0,
      timer: null,
      slidesDisplayed: 1,
      draggable: true,
    };
  },
  methods: {
    /* istanbul ignore next */
    slide(wrapper, items, prev, next) {
      if (this.sliderItems) {
        const slides = this.sliderItems.getElementsByClassName('slide');
        this.slidesLength = slides.length - (this.slidesDisplayed + 1);
        this.slideSize = slides[0].offsetWidth;
        if (this.slidesDisplayed > 1) {
          this.sliderItems.style.left = `-${this.slideSize}px`;
        }

        // non draggable if we have one slide
        if (this.draggable && this.slidesLength > 1) {
          // Mouse events
          this.sliderItems.addEventListener('mousedown', this.dragStart, {
            passive: true,
          });

          // Touch events
          this.sliderItems.addEventListener('touchstart', this.dragStart, {
            passive: true,
          });
          this.sliderItems.addEventListener('touchend', this.dragEnd, {
            passive: true,
          });
          this.sliderItems.addEventListener('touchmove', this.dragAction, {
            passive: true,
          });
        }
        this.sliderItems.addEventListener('mouseover', this.mouseOver, {
          passive: true,
        });
        this.sliderItems.addEventListener('mouseleave', this.mouseLeave, {
          passive: true,
        });

        // Click events if any controls
        const that = this;
        if (prev) {
          prev.addEventListener('click', function () {
            that.shiftSlide(-1, 'controls');
          });
        }
        if (next) {
          next.addEventListener('click', function () {
            that.shiftSlide(1, 'controls');
          });
        }

        // Transition events
        this.sliderItems.addEventListener('transitionend', this.checkIndex);
      }
    },
    /* istanbul ignore next */
    dragStart(e) {
      // e.preventDefault();
      this.stopTimer();
      this.posInitial = this.sliderItems.offsetLeft;
      if (e.type === 'touchstart') {
        this.posX1 = e.touches[0].clientX;
      } else {
        this.posX1 = e.clientX;
        document.addEventListener('mouseup', this.dragEnd, { passive: true });
        document.addEventListener('mousemove', this.dragAction, {
          passive: true,
        });
      }
    },
    /* istanbul ignore next */
    dragAction(e) {
      if (e.type === 'touchmove') {
        this.posX2 = this.posX1 - e.touches[0].clientX;
        this.posX1 = e.touches[0].clientX;
      } else {
        this.posX2 = this.posX1 - e.clientX;
        this.posX1 = e.clientX;
      }
      this.sliderItems.style.left =
        this.sliderItems.offsetLeft - this.posX2 + 'px';
    },
    /* istanbul ignore next */
    dragEnd(e) {
      this.posFinal = this.sliderItems.offsetLeft;

      if (this.posFinal - this.posInitial < -this.threshold) {
        this.shiftSlide(1, 'drag');
      } else if (this.posFinal - this.posInitial > this.threshold) {
        this.shiftSlide(-1, 'drag');
      } else {
        this.sliderItems.style.left = this.posInitial + 'px';
      }
      this.startTimer();

      document.removeEventListener('mouseup', this.dragEnd);
      document.removeEventListener('mousemove', this.dragAction);
    },
    /* istanbul ignore next */
    shiftSlide(slide, action) {
      this.sliderItems.classList.add('shifting');

      if (this.allowShift) {
        if (action === 'controls' || action === 'auto') {
          this.posInitial = this.sliderItems.offsetLeft;

          if (action === 'controls') {
            // reset the timer if autoPlay is true
            this.resetTimer();
          }
        }
        // we jump to one slide to the right
        if (slide === 1 && action !== 'custom') {
          this.sliderItems.style.left =
            this.posInitial - this.offset - this.slideSize + 'px';
          this.index++;
        } else if (slide === -1 && action !== 'custom') {
          // we jump to one slide to the left
          this.sliderItems.style.left =
            this.posInitial + this.offset + this.slideSize + 'px';
          this.index--;
        } else if (Number.isInteger(slide)) {
          // reset the timer if autoPlay is true
          this.resetTimer();
          // we jump to a specific slide
          this.sliderItems.style.left =
            -this.slideSize -
            (slide + 1) * this.offset -
            this.slideSize * slide +
            'px';
          this.index = slide;
        }
      }

      this.allowShift = false;
    },
    /* istanbul ignore next */
    checkIndex() {
      this.sliderItems.classList.remove('shifting');
      // if we are getting a negative index it means that we have reach the first slide going backwards
      // we need to reset the position of the container to make the user believe he sees an infinite loop

      if (this.index === -1) {
        this.sliderItems.style.left =
          -(this.slidesLength * this.slideSize) -
          this.slidesLength * this.offset +
          'px';
        this.index = this.slidesLength - 1;
      }

      // we reach the last slide and we need to reset the container to show the first slide
      if (this.index === this.slidesLength) {
        this.sliderItems.style.left = -(this.slideSize + this.offset) + 'px';
        this.index = 0;
      }

      this.allowShift = true;
    },
    /* istanbul ignore next */
    startTimer() {
      if (this.autoplay === true) {
        const that = this;
        this.timer = setInterval(() => {
          that.shiftSlide(1, 'auto');
        }, this.timerInterval);
      }
    },
    /* istanbul ignore next */
    stopTimer() {
      if (this.autoplay === true) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    /* istanbul ignore next */
    resetTimer() {
      this.stopTimer();
      this.startTimer();
    },
    /* istanbul ignore next */
    mouseOver() {
      this.stopTimer();
    },
    /* istanbul ignore next */
    mouseLeave() {
      this.startTimer();
    },
    parseSlides(slides) {
      // we create a new array based on the original
      // and we are going to add the first and last slide at the 1st and last place
      // ex: [4] 1 2 3 4 [1]
      const start = slides.slice(0, this.slidesDisplayed);
      let parsedSlides = [...slides];
      if (slides.length > 1) {
        parsedSlides = [...slides, ...start];
        parsedSlides.unshift(slides[slides.length - 1]);
      }
      return JSON.parse(JSON.stringify(parsedSlides)).map((slide, index) => {
        slide.unique_id = index;
        return slide;
      });
    },
  },
  /* istanbul ignore next */
  mounted() {
    this.index = 0;
    this.slider = this.$refs.slider;
    this.sliderItems = this.$refs.slides;
    this.prev = this.$refs.prev;
    this.next = this.$refs.next;
    this.slide(this.slider, this.sliderItems, this.prev, this.next);
    this.startTimer();
    // Making sure we stop the timer on page refresh
    window.addEventListener('beforeunload', () => {
      this.sliderItems.removeEventListener('transitionend', this.checkIndex);
      this.stopTimer();
    });
  },
  /* istanbul ignore next */
  destroyed() {
    // if the component is destroyed we stop the timer to avoid any memory leak
    this.stopTimer();
  },
};
