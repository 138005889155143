import { getCookie, setCookie } from './cookieHelpers';

const LANDING_LOCATION = {
  us: 'USA',
  gb: 'UK',
  au: 'Australian',
};
const REDIRECT = {
  us: 'USA',
  gb: 'UK',
  au: 'Australia',
};

export function getRedirectUrl(url) {
  const origin = new RegExp(
    '(http|https)://' + window.location.host + '(/au|/gb)?',
    'g'
  );
  return window.location.href.replace(origin, url);
}

export function getIpUrl() {
  const region = ['au', 'gb'].includes($nuxt.$store.state.ipCountry)
    ? `/${$nuxt.$store.state.ipCountry}`
    : '';
  return window.location.origin + region;
}

function trackActionOnGeoPopup(action) {
  const trackingData = {
    name: action === 'show' ? 'NOTIFICATION_ISSUED' : 'NOTIFICATION_RESOLVED',
    country: $nuxt.$store.state.region,
    notification_message: REDIRECT[$nuxt.$store.state.ipCountry],
    notification_action: action,
  };
  $nuxt.$store.commit('analytics/notificationAction', trackingData);
}

function removeBackSlash(url) {
  if (url.endsWith('/')) {
    return url.replace(/\/+$/, '');
  }
  return url;
}

function setGeoSessionStorage(value) {
  // this will make sure that the user only get redirected once until he closes his tab
  // this will also prevent the user to be redirect if changing the region from the dropdown
  sessionStorage.setItem('geoPopup', value);
}

function setGeoPopupCookie() {
  setCookie('geo', $nuxt.$store.state.ipCountry, 90);
  setGeoSessionStorage(false);
}

export const checkGeoPopupCookie = () => {
  const cookie = getCookie('geo');
  // check if no session storage value found
  if (!sessionStorage.getItem('geoPopup') && !cookie) {
    $nuxt.$store.commit('triggerGeoPopup', true);
    $nuxt.$store.commit(
      'setGeoRedirectUrl',
      removeBackSlash(getRedirectUrl(getIpUrl()))
    );
    trackActionOnGeoPopup('show');
  }
};

export const geoPopupStatus = () => {
  return $nuxt.$store.state.showGeoPopup;
};

export const geoPopupContent = () => {
  return {
    uid: 'geo-popup',
    background: 'purple',
    text: `You’ve landed on our ${
      LANDING_LOCATION[$nuxt.$store.state.region]
    } website`,
    ctaLink: $nuxt.$store.state.geoRedirectUrl,
    ctaText: `View Deputy ${REDIRECT[$nuxt.$store.state.ipCountry]}`,
  };
};

export const hideGeoPopup = () => {
  $nuxt.$store.commit('triggerGeoPopup', false);
  setGeoSessionStorage(false);
  trackActionOnGeoPopup('close');
};

export const redirectUser = () => {
  setGeoPopupCookie();
  trackActionOnGeoPopup('click');
};
