import { checkIfDeputyCustomer, checkIfPaidCustomer } from '~/utils/analytics';

export default {
  data() {
    return {
      isDeputyCustomer: false,
      isPaidCustomer: false,
    };
  },
  mounted() {
    this.checkDeputyCustomer();
    this.checkPaidCustomer();
  },
  methods: {
    checkPaidCustomer() {
      window.isPaidCustomer = checkIfPaidCustomer();
      if (window.isPaidCustomer) {
        this.isPaidCustomer = true;
        this.$store.commit('setPaidCustomer', true);
      }
    },
    checkDeputyCustomer() {
      window.isDeputyCustomer = checkIfDeputyCustomer();
      if (window.isDeputyCustomer) {
        this.isDeputyCustomer = true;
        this.$store.commit('setCustomer', true);
      }
    },
  },
};
