import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      stats: state => state.stats,
    }),
  },
  methods: {
    interpolateStats(stringToInterpolate) {
      const regex = /\${[^{]+}/g;
      return stringToInterpolate.replace(regex, match => {
        const key = match.slice(2, -1).trim();
        return (
          this.stats[key] || `:: Key '${key}' does not exist in statistics ::`
        );
      });
    },
  },
};
