// @see https://www.npmjs.com/package/smoothscroll-polyfill
export default {
  mounted() {
    this.loadScrollPolyfillScript();
  },
  methods: {
    loadScrollPolyfillScript() {
      if (!document.querySelector('#smoothscroll-script')) {
        const scriptTag = document.createElement('script');
        scriptTag.setAttribute(
          'src',
          'https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js'
        );
        scriptTag.async = true;
        scriptTag.id = 'smoothscroll-script';
        document.head.appendChild(scriptTag);
      }
    },
  },
};
