export function getRegionFromPath(path) {
  if (/^\/au(\?.*|\/\?.*|\/.*)?$/.test(path)) {
    return 'au';
  } else if (/^\/gb(\?.*|\/\?.*|\/.*)?$/.test(path)) {
    return 'gb';
  } else {
    return 'us';
  }
}

export function stripRegion(url) {
  return url.replace(/^\/?(us|au|gb)\//, '/');
}

export function kebabize(str) {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
        : letter;
    })
    .join('');
}

export function parseJson(jsonString) {
  const parsedJson = isValidJson(jsonString);
  if (parsedJson) {
    return parsedJson;
  }

  try {
    const decodedJson = decodeURIComponent(jsonString);
    return JSON.parse(decodedJson);
  } catch (e) {
    return false;
  }
}

export function isValidJson(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
}

/**
 * Checks if we are indeed in production enviroment so we load the proper
 * services tokens and variables (DATADOG, GTM, etc)
 *
 * @param {string} branch The branch name
 * @param {string} deputyEnv The Deputy environment (eg: production, staging)
 * @param {string} nodeEnv The Node environment (eg: testing, production)
 * @return {Boolean} Whether we should use prod services or not
 */
export function isProdForServices(branch, deputyEnv, nodeEnv) {
  return (
    branch === 'main' && deputyEnv === 'production' && nodeEnv === 'production'
  );
}
