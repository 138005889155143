import throttle from 'lodash/throttle';

export const state = () => ({
  lastY: null,
  currentY: null,
  scrollDirectionInitalized: false,
});

export const getters = {
  scrollDirection(state) {
    // will default to 'down' if 'initalizeScrollDirection' hasn't yet run
    return state.currentY < state.lastY ? 'up' : 'down';
  },
};

export const mutations = {
  SET_LAST_Y(state, payload) {
    state.lastY = payload;
  },
  SET_CURRENT_Y(state, payload) {
    state.currentY = payload;
  },
  SET_SCROLL_DIRECTION_INITALIZED(state) {
    state.scrollDirectionInitalized = true;
  },
};

export const actions = {
  initalizeScrollDirection({ commit, state }) {
    if (state.scrollDirectionInitalized || !window) {
      return null;
    }

    function updatePagePosition() {
      commit('SET_LAST_Y', state.currentY);
      commit('SET_CURRENT_Y', window.pageYOffset);
    }
    window.onscroll = throttle(updatePagePosition, 100);

    commit('SET_SCROLL_DIRECTION_INITALIZED');
  },
};
