const statsJson = () =>
  import('../lib/storyblok-local-json/storyblok-stats.json');
const costsJson = () =>
  import('../lib/storyblok-local-json/storyblok-costs.json');
const addOnCostsJson = () =>
  import('../lib/storyblok-local-json/storyblok-add-on-costs.json');
const industryJson = () =>
  import('../lib/storyblok-local-json/storyblok-industries.json');
const filtersJson = () =>
  import('../lib/storyblok-local-json/storyblok-filters.json');
const planJson = () =>
  import('../lib/storyblok-local-json/storyblok-customer-plan.json');
const integrationsJson = () =>
  import('../lib/storyblok-local-json/storyblok-integrations.json');
const complianceStatesJson = () =>
  import('../lib/storyblok-local-json/storyblok-compliance-states.json');
import terms from '../lib/storyblok-local-json/storyblok-signup-terms.json';

export const state = () => ({
  stats: {},
  costs: {},
  addOnCosts: {},
  terms: terms,
  cacheVersion: '',
  ipCountry: 'us',
  pricingCountry: 'us',
  region: 'us',
  industry: {},
  filters: {},
  plan: {},
  isCustomer: null,
  isPaidCustomer: null,
  optimizelyDataRetrieved: false,
  personalisationStatus: null, // {useControl: true/false,  experimentId: <experimentId>> }
  story: {
    content: {},
  },
  trainingData: {},
  problemsSolutionsCardsQuotes: null,
  hideDrift: false,
  showGdpr: false,
  showFreemium: false,
  driftIsOpen: false,
  loadAssets: null,
  showGeoPopup: false,
  geoRedirectUrl: '',
  errorMsgUrls: {
    marketoFormErrorMessage: 'cdn/stories/us/marketo-form-error-message',
    bookAConsultErrorMessage: 'cdn/stories/us/book-a-consult-error-message',
    errorPage: 'cdn/stories/us/error',
  },
  integrations: {},
  complianceStates: [],
});

export const getters = {
  getDriftStatus(state) {
    return state.hideDrift;
  },
  getHomeUrl(state) {
    return state.region === 'us' ? '/' : `/${state.region}`;
  },
  getLoadAssetsStatus(state) {
    return state.loadAssets;
  },
  getCustomer(state) {
    return state.isCustomer;
  },
  getPaidCustomer(state) {
    return state.getPaidCustomer;
  },
};

export const mutations = {
  saveStory(state, story) {
    state.story = story;
  },
  saveTrainingData(state, trainingData) {
    state.trainingData = trainingData;
  },
  setPaddingTop(state, paddingTop) {
    // we are getting the first component from the story object
    savePaddingTopToStory(0);
    const isFirstComponentMobileOnly = state.story.content?.content[0];
    if (isFirstComponentMobileOnly && isFirstComponentMobileOnly.mobile_only) {
      savePaddingTopToStory(1);
    }

    function savePaddingTopToStory(index) {
      let storyString = JSON.stringify(state.story.content?.content[index]);
      // all our component have the spacer_bottom field
      // we will look for it and replace the value
      const found = /\bspacer_bottom\b/g.test(storyString);
      const paddingTopString = JSON.stringify(paddingTop);
      if (found) {
        storyString = storyString.replace(
          /\bspacer_bottom\b/g,
          `padding_top": ${paddingTopString},"spacer_bottom`
        );
        // we save the new data with the padding
        state.story.content.content[index] = JSON.parse(storyString);
      }
    }
  },

  setCacheVersion(state, version) {
    state.cacheVersion = version;
  },
  setIpCountry(state, country) {
    state.ipCountry = country;
  },
  setRegion(state, region) {
    state.region = region;
  },
  setPricingCountry(state, country) {
    state.pricingCountry = country;
  },

  // Personalisation
  setCustomer(state, isCustomer) {
    state.isCustomer = isCustomer;
  },
  setPaidCustomer(state, isPaidCustomer) {
    state.isPaidCustomer = isPaidCustomer;
  },
  setOptimizelyData(state, retrieved) {
    state.optimizelyDataRetrieved = retrieved;
  },
  setPersonalisationStatus(state, personalisationStatus) {
    state.personalisationStatus = personalisationStatus;
  },

  // Integrations
  search(state, payload) {
    state.integrations.search = payload;
  },
  clearSearch(state) {
    state.integrations.search = '';
  },
  selectedCategory(state, payload) {
    state.integrations.selected_category = payload;
  },
  clearSelectedCategory(state) {
    state.integrations.selected_category = '';
  },

  // cms-problems-solutions-cards
  setProblemsSolutionsCardsQuotes(state, quotes) {
    state.problemsSolutionsCardsQuotes = quotes;
  },

  // Drift
  hideDrift(state, hide = Boolean) {
    state.hideDrift = hide;
  },
  setDriftIsOpen(state, isOpen) {
    state.driftIsOpen = isOpen;
  },

  // GDPR
  triggerGDPR(state, show = Boolean) {
    state.showGdpr = show;
  },

  // Freemium
  triggerFreemium(state, show = Boolean) {
    state.showFreemium = show;
  },

  // LazyLoad asset
  setLoadAssets(state, script = String) {
    let assets = script;
    if (state.loadAssets && script !== 'all') {
      // we merge the 2 strings avoiding duplicate
      assets = [
        ...new Set([...state.loadAssets.split(','), ...script.split(',')]),
      ].join(',');
    }
    if (state.loadAssets !== 'all') {
      state.loadAssets = assets;
    }
  },

  // Geo Popup
  triggerGeoPopup(state, show = Boolean) {
    state.showGeoPopup = show;
  },
  setGeoRedirectUrl(state, url = String) {
    state.geoRedirectUrl = url;
  },

  SET_STATS_DATA(state, payload) {
    state.stats = payload;
  },
  SET_COSTS_DATA(state, payload) {
    state.costs = payload;
  },
  SET_ADD_ON_COSTS_DATA(state, payload) {
    state.addOnCosts = payload;
  },
  SET_INDUSTRY_DATA(state, payload) {
    state.industry = payload;
  },
  SET_FILTERS_DATA(state, payload) {
    state.filters = payload;
  },
  SET_PLAN_DATA(state, payload) {
    state.plan = payload;
  },
  SET_INTEGRATIONS_DATA(state, payload) {
    state.integrations = payload;
  },
  SET_COMPLIANCE_STATES_DATA(state, payload) {
    state.complianceStates = payload;
  },
};

export const actions = {
  loadCacheVersion({ commit }) {
    return this.$storyapi.get(`cdn/spaces/me`).then(res => {
      commit('setCacheVersion', res.data.space.version);
    });
  },
  setStatsData({ commit }) {
    statsJson().then(data => {
      commit('SET_STATS_DATA', data.default);
    });
  },
  setCostsData({ commit }) {
    costsJson().then(data => {
      commit('SET_COSTS_DATA', data.default);
    });
  },
  setAddOnCostsData({ commit }) {
    addOnCostsJson().then(data => {
      commit('SET_ADD_ON_COSTS_DATA', data.default);
    });
  },
  setIndustryData({ commit }) {
    industryJson().then(data => {
      commit('SET_INDUSTRY_DATA', data.default);
    });
  },
  setFiltersData({ commit }) {
    filtersJson().then(data => {
      commit('SET_FILTERS_DATA', data.default);
    });
  },
  setPlanData({ commit }) {
    planJson().then(data => {
      commit('SET_PLAN_DATA', data.default);
    });
  },
  setIntegrationsData({ commit }) {
    integrationsJson().then(data => {
      commit('SET_INTEGRATIONS_DATA', data.default);
    });
  },
  setComplianceStatesData({ commit }) {
    complianceStatesJson().then(data => {
      commit('SET_COMPLIANCE_STATES_DATA', data.default);
    });
  },
};
