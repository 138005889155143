import { mapGetters } from 'vuex';
import { getRecaptchaSiteKey } from '~/utils/recaptcha';

export default {
  data() {
    const recaptchaSiteKey = getRecaptchaSiteKey();
    return {
      assets: {
        marketo: {
          src: '//insight.deputy.com/js/forms2/js/forms2.min.js',
          isLoaded: false,
        },
        marketoPrefillFormPlus: {
          src: '//insight.deputy.com/rs/503-OWD-828/images/teknkl-formsplus-core-1.0.8.js',
          isLoaded: false,
        },
        marketoPrefillSimpleDTO: {
          src: '//insight.deputy.com/rs/503-OWD-828/images/teknkl-simpledto-2.0.4.js',
          isLoaded: false,
        },
        recaptcha: {
          src: `//www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}&badge=bottomleft`,
          isLoaded: false,
        },
        // chilipiper: {
        //   src: '//js.chilipiper.com/marketing.js',
        //   isLoaded: false,
        //   usercentrics: 'Chili Piper',
        // },
        // munchkin: {
        //   src: '//munchkin.marketo.net/munchkin.js',
        //   isLoaded: false,
        //   callback: () => this.initMunchkin(),
        //   usercentrics: 'Marketo',
        // },
        // facebook: {
        //   script: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '887751571280750');fbq('track', 'PageView');`,
        //   isLoaded: false,
        //   usercentrics: 'Facebook Social Plugins',
        // },
        // linkedin: {
        //   script: `_linkedin_partner_id = "285754";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);`,
        //   isLoaded: false,
        //   usercentrics: 'LinkedIn Insight Tag',
        // },
        // instagram: {
        //   src: '//www.instagram.com/embed.js',
        //   isLoaded: false,
        //   usercentrics: 'Instagram Content',
        // },
        // bing: {
        //   script: `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:" 16005490"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
        //   isLoaded: false,
        //   usercentrics: 'Microsoft Advertising Remarketing',
        // },
        // zoomInfo: {
        //   script:
        //     'window[(function(_CEs,_oq){var _F6="";for(var _Ly=0;_Ly<_CEs.length;_Ly++){_oq>1;var _cI=_CEs[_Ly].charCodeAt();_cI-=_oq;_F6==_F6;_cI!=_Ly;_cI+=61;_cI%=94;_cI+=33;_F6+=String.fromCharCode(_cI)}return _F6})(atob("ZFNafHl0b21+VW8l"), 10)] = "165e98b0421669240313"; var zi = document.createElement("script"); (zi.type = "text/javascript"), (zi.async = true), (zi.src = (function(_fH9,_tE){var _Ra="";for(var _Mq=0;_Mq<_fH9.length;_Mq++){_mr!=_Mq;var _mr=_fH9[_Mq].charCodeAt();_mr-=_tE;_tE>8;_mr+=61;_Ra==_Ra;_mr%=94;_mr+=33;_Ra+=String.fromCharCode(_mr)}return _Ra})(atob("Ljo6NjleU1MwOVJAL1E5KTgvNjo5Uik1M1NAL1E6Jy1SMDk="), 36)), document.readyState === "complete"?document.body.appendChild(zi): window.addEventListener("load", function(){ document.body.appendChild(zi) });',
        //   isLoaded: false,
        //   usercentrics: 'ZoomInfo',
        // },
        // g2: {
        //   script:
        //     "(function (c, p, d, u, id, i) {id = '';u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;i = document.createElement('script');i.type = 'application/javascript';i.async = true;i.src = u;d.getElementsByTagName('head')[0].appendChild(i);}(\"676\", document.location.href, document));",
        //   isLoaded: false,
        //   usercentrics: 'G2 Crowd',
        // },
        // '6sense': {
        //   src: 'https://j.6sc.co/j/dc3fbca1-5a4b-4b20-8e93-d64b2e155652.js',
        //   id: '6senseWebTag',
        //   isLoaded: false,
        //   usercentrics: '6sense',
        // },
        // crazyEgg: {
        //   src: '//script.crazyegg.com/pages/scripts/0063/4307.js',
        //   isLoaded: false,
        //   usercentrics: 'Crazy Egg',
        // },
        // infiniGrow: {
        //   script: `(function (p, l, o, w, i, n, g) { if (!p[i]) { p.GlobalInfinigrowObject = {}; p.GlobalInfinigrowObject.userId = 'auth0|6644c958580d99ef27d3647d'; p.GlobalInfinigrowObject.InfinigrowNamespace = i; p[i] = function () { (p[i].q = p[i].q || []).push(arguments); }; p[i].q = p[i].q || []; n = l.createElement(o); g = l.getElementsByTagName(o)[0]; n.async = 1; n.src = w; g.parentNode.insertBefore(n, g); } }(window, document, 'script', 'https://ddzuuyx7zj81k.cloudfront.net/2.0.0/attributionSnippet.js', 'infinigrow'));`,
        //   isLoaded: false,
        //   usercentrics: 'InfiniGrow',
        // },
        // segment: {
        //   /**
        //    * Get Google's gclid from the URL query parameter and return the objected expected by Segment's page call.
        //    * The analytics.page() function is called at the end of the 'segment-script' tag bellow
        //    * @see: https://segment.com/docs/connections/spec/page/
        //    * @see: https://support.google.com/google-ads/answer/9744275
        //    */
        //   script: `function getGclid(){if("undefined"!=typeof window){const e=new URL(window.location.href).searchParams.get("gclid");if(e)return{properties:{gclid:e}}}return{}};!function(){var e=window.analytics=window.analytics||[];if(!e.initialize)if(e.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{e.invoked=!0,e.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"],e.factory=function(t){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(t),e.push(n),e}};for(var t=0;t<e.methods.length;t++){var n=e.methods[t];e[n]=e.factory(n)}e.load=function(t,n){var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.segment.com/analytics.js/v1/${this.$root.context.env.segmentKey}/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r),e._loadOptions=n},e._writeKey="${this.$root.context.env.segmentKey}",e.SNIPPET_VERSION="4.15.3",e.load("${this.$root.context.env.segmentKey}"),e.page(getGclid())}}();`,
        //   isLoaded: false,
        //   usercentrics: 'Segment',
        // },
      },
    };
  },
  watch: {
    getLoadAssetsStatus: {
      handler(assets) {
        if (process.client) {
          const loadAssets =
            assets === 'all' ? Object.keys(this.assets) : assets.split(',');
          loadAssets.map(value => {
            const tValue = value.trim();
            if (this.assets.hasOwnProperty(tValue)) {
              this.loadScript(this.assets[tValue], tValue);
            }
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getLoadAssetsStatus: 'getLoadAssetsStatus',
    }),
  },
  methods: {
    loadScript(asset, name) {
      if (!asset.isLoaded) {
        const tag = document.createElement('script');

        // if it's a src we need to append the source and allow a callback
        if (asset.hasOwnProperty('src')) {
          tag.setAttribute('src', asset.src);
          tag.async = true;
          tag.onload = asset.hasOwnProperty('callback')
            ? asset.callback
            : () => this.assetIsLoaded(name);
          if (asset.hasOwnProperty('id')) {
            tag.setAttribute('id', asset.id);
          }
          this.appendScriptToHead(tag);
        }
        // it's a script tag
        if (asset.hasOwnProperty('script')) {
          // if a script block we need to inject it between the script tags
          tag.innerHTML = asset.script;
          this.appendScriptToHead(tag, name, true);
        }
        // it's a no-script tag
        if (asset.hasOwnProperty('noScript')) {
          // if a noscript block we need to inject it between the noscript tags
          const noScript = document.createElement('noscript');
          noScript.innerHTML = asset.noScript;
          this.appendScriptToHead(noScript, name, true);
        }
      }
    },
    appendScriptToHead(tag, name = null, markAsLoad = false) {
      if (document?.head) {
        try {
          document.head.appendChild(tag);
          if (markAsLoad) {
            this.assetIsLoaded(name);
          }
        } catch (e) {
          console.error('load script error:', e);
        }
      }
    },
    // initMunchkin() {
    //   Munchkin.init('503-OWD-828');
    //   this.assetIsLoaded('munchkin');
    // },
    assetIsLoaded(name) {
      if (this.assets.hasOwnProperty(name)) {
        this.assets[name].isLoaded = true;
      }
    },
  },
};
