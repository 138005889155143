export const COOKIE_NAME_PREFIX = 'dp_exp_';

/**
 * @typedef CampaignExperimentConfig
 * @type {object}
 * @property {string} COOKIE_NAME
 * @property {string} CAMPAIGN_NAME
 * @property {string} CONTROL_NAME
 * @property {string} VARIATION_NAME
 * @property {string} VARIATION_LANDING_PATH
 */

/** @type {Object.<string, CampaignExperimentConfig>} */
export const EXPERIMENTS_CONFIG = {};
