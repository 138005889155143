import smoothScrollPolyfill from './smoothScrollPolyfill';

export default {
  mixins: [smoothScrollPolyfill],
  mounted() {
    window.addEventListener('hashchange', this.locationHashChanged);
    this.$nextTick(() => {
      // this is a hack, because some url have an anchor tag #anchor
      // by default the browser will scroll to the element with the same ID
      // this is just to make sure even if the browser has scrolled already
      // we update the scroll position correctly
      setTimeout(() => {
        this.$store.dispatch('menu/setHeaderOffset');
        this.locationHashChanged();
      }, 50);
    });
  },
  methods: {
    locationHashChanged() {
      const hash = document.location.hash;
      if (hash) {
        this.anchored = hash.replace('#', '');
        this.scrollToAnchor(hash);
      }
    },
    scrollToAnchor(anchor) {
      const headerOffset = this.$store.state.menu.headerOffset;
      const element = document.querySelector(anchor);
      if (element) {
        const topScroll =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          headerOffset;
        // scroll to the element with the appropriate offset
        window.scrollTo({ top: topScroll, behavior: 'smooth' });
      }
    },
  },
};
