// Client side page Modifications for freemium experience
export default function freemiumMods() {
  window.dptyStarterPlan = true;
  const cta1 = 'Use Deputy for free';
  const cta2 = 'Get started';
  const cta3 = 'Get started';
  const signupTerms = 'No credit card required.';
  const termsSelectors = [
    '.g-header-animation__terms',
    '.g-header__primary-cta-info',
    '.g-feature-header__text .type-notes',
    '.g-blog-index__cta-subline',
    '.js-signup-notes',
  ];

  // modifications
  document
    .querySelectorAll('.js-modal-trigger[data-modal-target="#signupModal"]')
    .forEach(function (cta) {
      cta.innerText = cta1;
    });

  const dmCta = document.getElementById(
    '.g-navigation__button[data-modal-target="#signupModal"]'
  );
  if (dmCta) {
    dmCta.innerText = cta2;
  }

  const mmCta = document.querySelector(
    '#menu-navigation-mobile [data-modal-target="#signupModal"]'
  );
  if (mmCta) {
    mmCta.innerText = 'Join for free';
  }

  termsSelectors.forEach(selector => {
    const terms = document.querySelector(selector);
    if (terms) {
      terms.innerText = signupTerms;
    }
  });

  document.querySelectorAll('.js-signup-btn').forEach(cta => {
    cta.innerText = cta2;
  });

  document.querySelectorAll('#signup-modal-button').forEach(cta => {
    cta.innerText = cta3;
  });
}
