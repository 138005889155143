import { create, track } from '~/utils/analytics';

export default {
  data() {
    return {
      isShown: false,
    };
  },
  methods: {
    openVideoModal(modalId) {
      this.isShown = true;
      this.$emit('modal-opened');
      // this will add the modalId to videoPlay array when we open the modal
      // this is used to don't show exit pop up when modal is open
      this.$store.commit('modal/SET_VIDEO_PLAY', modalId);

      // track modal open
      /* istanbul ignore next */
      if (typeof window.analytics !== 'undefined') {
        const startedEvent = create({
          page: document.location.href,
          modalId: modalId || 'video modal',
        });
        track('Video modalOpened', startedEvent);
      }
    },
    closeVideoModal(modalId) {
      this.isShown = false;
      this.$emit('modal-closed');
      // this will pop the modalId from videoPlay array when we close the modal
      this.$store.commit('modal/SET_VIDEO_CLOSE', modalId);

      // track modal close
      /* istanbul ignore next */
      if (typeof window.analytics !== 'undefined') {
        const startedEvent = create({
          page: document.location.href,
          modalId: modalId || 'video modal',
        });
        track('Video modalClosed', startedEvent);
      }
    },
  },
};
