import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import Airgap from '../utils/airgap';

export default context => {
  Airgap.executeWhenConsentAccepted('Datadog', () => initDatadog(context));
};

function initDatadog(context) {
  console.info('rum init');
  datadogLogs.init({
    clientToken: context.env.datadog.DATADOG_CLIENT_TOKEN_LOGS,
    site: 'datadoghq.com',
    service: context.env.datadog.DATADOG_SERVICE,
    env: context.env.datadog.DATADOG_ENV,
    version: context.env.datadog.DATADOG_VERSION,
    silentMultipleInit: true,
    forwardErrorsToLogs: true,
    sampleRate: 1,
  });

  datadogRum.init({
    clientToken: context.env.datadog.DATADOG_CLIENT_TOKEN_RUM,
    applicationId: context.env.datadog.DATADOG_APPLICATION_ID,
    site: 'datadoghq.com',
    service: context.env.datadog.DATADOG_SERVICE,
    env: context.env.datadog.DATADOG_ENV,
    version: context.env.datadog.DATADOG_VERSION,
    silentMultipleInit: true,
    sampleRate: 1,
    premiumSampleRate: 0,
    trackInteractions: true,
  });
}
