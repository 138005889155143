import parseLinkHref from './parseLinkHref';

export function appendStoryblokReleaseID(storyUrl, storyParams) {
  const releaseID = /storyblok_release=([^&]+)/.exec(storyUrl);
  if (releaseID) {
    storyParams.from_release = releaseID[1];
  }
  return storyParams;
}

export function getStoryblokRequestPath(path, region, isDev) {
  // Establish Storyblok API url from the route
  // If in 'preview' or 'localhost' mode, then both `/us` and
  // `/` will work (fetching from the `us` story). Otherwise
  // it will return 404 when `/us` is used in the url (for
  // 'production' , 'staging' and 'dev')
  let requestPath =
    region !== 'us' ||
    ((process.env.isPreview === true || isDev === true) && /^\/us\b/.test(path))
      ? path
      : `/us${path}`;

  // Important reminder, our urls on the website don't end with a /
  // but our pages on Netlify, where this Javascript is running, do
  // Insert a trailing slash in case we accidentally forget to add it
  // (primarily for when working locally).
  if (!requestPath.endsWith('/')) {
    requestPath = `${requestPath}/`;
  }

  // Remove extra /us/us/ for when the environment has doubled the region
  // in the url
  requestPath = requestPath.replace(/^\/us\/us\b/, '/us');

  return requestPath;
}

/**
 * Converts Storyblok CTAs by modifying the provided storyblok object.
 * @param {Object} storyblok - The Storyblok object to be modified.
 * @param {string} urlParams - Additional URL parameters to be appended to the CTA links.
 * @param {string} analyticsId - The analytics ID to be assigned to CTAs without an existing analytics ID.
 * @return {Object} - The modified Storyblok object.
 */
export function convertStoryblokCTAs(storyblok, urlParams = '', analyticsId) {
  const blok = JSON.parse(JSON.stringify(storyblok));

  if (!blok.ctas?.length) {
    return blok;
  }

  // TODO we should pass the correct information directly from Storyblok
  const modalTargets = {
    signup: '#signupModal',
    consultation: '#bookADemo',
  };

  blok.ctas.forEach(cta => {
    cta.modalTarget = modalTargets[cta.type] ?? null;
    cta.link = cta.link?.cached_url
      ? `${parseLinkHref(cta.link)}${urlParams}`
      : null;
    if (!cta.analyticsId && analyticsId) {
      cta.analyticsId = analyticsId;
    }
    delete cta.type;
  });

  return blok;
}
