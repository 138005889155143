import Storyblok from 'storyblok-js-client';
const SB = new Storyblok({});

export default function richtext(
  value,
  stripParagraphs = false,
  preventReactiveness = false
) {
  if (!value) {
    return '';
  }

  if (preventReactiveness) {
    value = JSON.parse(JSON.stringify(value));
  }

  function replaceFix(content) {
    // fix for hubspot
    return content
      .replace(/\/\/ <!\[CDATA(.)*]]>/g, '')
      .replace('<center>', '')
      .replace('</center>', '');
  }

  // the resolver will loop though the object and find any blok component
  SB.setComponentResolver((component, blok) => {
    return `<component :blok='${replaceFix(
      JSON.stringify(blok)
    )}' is='${component}' slug=""></component>`;
  });

  if (typeof value !== 'object') {
    return '';
  }

  let rendered = SB.richTextResolver.render(value);

  // extra check take the h1-h4 tag out of the text in case an editor copy and paste the text with different format
  // and the text will be served as whatever parent tag is in code.

  if (stripParagraphs) {
    rendered = rendered
      .replace(/<h[1-6]>/, '')
      .replace(/<\/h[1-6]>/, '')
      .replace('<p>', '')
      .replace('</p>', '');
  }

  // remove the color style inside the <span> tags from the rendered HTML to remove inline style added by SB
  rendered = rendered.replace(
    /(<span style=")color:.*?;?(.*?)(">.*?<\/span>)/gs,
    '$1$2$3'
  );

  if (/^<p><\/p>$/.test(rendered)) {
    rendered = '';
  }

  return rendered;
}

/**
 * a StoryBlok richtext field that hasn't had any content entered will default to an empty string
 * once some text gets added to it, it will become an object
 */
export const richtextType = [Object, String];

/**
 * removes empty HTML tags from a string
 * useful for determining if a StoryBlok richtext object needs to be rendered
 * @param  {[string]} htmlString
 * @return {[string]}      htmlString cleaned of empty tags
 */
export function removeEmptyTags(htmlString) {
  return htmlString.replace(/<[\S]+><\/[\S]+>/g, '');
}
