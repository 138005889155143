export const state = () => ({
  articles: [],
  totalPages: null,
});

export const mutations = {
  saveArticles(state, payload) {
    state.articles = payload;
  },

  saveTotalPages(state, totalPages) {
    state.totalPages = totalPages;
  },
};
