import {
  create,
  getSignupType,
  identify,
  setupExperimentTracking,
  setupInMarketTracking,
  track,
  trackInMarketConversion,
  getEmailHashSha512,
  getEmailDomain,
  getExperimentDataFromCampaign,
} from '~/utils/analytics';
import { getCookie, setCookie, createCookie } from '~/utils/cookieHelpers';
import { EXPERIMENTS_CONFIG } from '~/utils/experimentsConfig';

import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      form: state => state.form,
      analytics: state => state.analytics,
    }),
  },
  watch: {
    'analytics.signupStarted': function (isSignupStarted) {
      // if it's true
      if (isSignupStarted) {
        const startedEventObj = {
          source: this.form.formId,
          signup: {
            type: getSignupType(),
          },
        };
        if (this.analytics.actionSource) {
          startedEventObj.signup.actionSource = this.analytics.actionSource;
        }

        const startedEvent = create(startedEventObj);
        track('SIGNUP_STARTED', startedEvent);
        // reset the value in case the user don't attempt this form later and
        // use another form on the page for his signup attempt
        this.$store.commit('analytics/signupStarted', false);
      }
    },
    'analytics.signupAttempt': async function (isSignupAttempt) {
      if (isSignupAttempt) {
        const signupEventData = await this.getSignupEventData();
        track('SIGNUP_ATTEMPT', signupEventData);

        trackInMarketConversion('SIGNUP_ATTEMPT');
        this.$store.commit('analytics/signupAttempt', false);
        if (this.form.social) {
          this.$store.commit('form/setSocial', null);
        }
      }
    },
    'analytics.signupFailed.status': async function (isSignupFailed) {
      if (isSignupFailed) {
        const errorData = {
          deputy_failure_reason: this.analytics.signupFailed.error,
        };
        const signupEventData = await this.getSignupEventData();
        const errorEvent = { errorData, ...signupEventData };
        // get the generic error
        const firstError =
          Object.keys(this.analytics.signupFailed.error).length === 1
            ? Object.values(this.analytics.signupFailed.error)[0]
            : this.analytics.signupFailed.error;
        errorEvent.signup.error = {
          message: firstError,
        };
        track('SIGNUP_FAILURE', errorEvent);

        // we reset the value in the store
        this.$store.commit('analytics/signupFailed', {
          status: false,
          error: {},
        });
      }
    },
    'analytics.loginStarted.status': function (isLoginStarted) {
      if (isLoginStarted) {
        const loginEventData = create({
          source: this.analytics.loginStarted.source,
        });
        track('LOGIN_STARTED', loginEventData);

        // we reset the value in the store
        this.$store.commit('analytics/loginStarted', {
          status: false,
          source: 'unknown',
        });
      }
    },
    'analytics.signupDone.status': async function (isSignupDone) {
      if (isSignupDone === 200) {
        const { userId } = this.analytics.signupDone.user;

        // TODO
        // Merge our data in case we get new/different data returned from the server,
        // also provides a more accurate timestamp
        const completedEventData = await this.getSignupEventData();
        completedEventData.signup.user.deputyUUID = userId;
        completedEventData.context.user.deputyUUID = userId;

        // // Connect the user's anonymousID with the account ID returned from once
        identify(userId, completedEventData);

        if (this.analytics.signupDone.action === 'signup') {
          track('SIGNUP_SUCCESS', completedEventData);

          trackInMarketConversion('SIGNUP_SUCCESS');
        } else {
          track('SIGNUP_EXISTING', completedEventData);
        }
      }
    },
    'analytics.notificationAction.notification_action': function (
      notificationAction
    ) {
      try {
        track(
          this.analytics.notificationAction.name,
          create({
            notification_type: 'pop_up',
            deputy_event_source: 'geo_popup_notification',
            screen: 'Website',
            country: this.analytics.notificationAction.country,
            notification_message:
              this.analytics.notificationAction.notification_message,
            notification_action: notificationAction,
            deputy_flag: window.location.hostname.includes('deputy.com')
              ? 0
              : 1,
          })
        );
      } catch (err) {
        console.error('Segment', err);
      }
    },
    'analytics.viewOpened': function (modalTarget) {
      if (modalTarget) {
        const viewOpenedObj = {
          view: {
            details: {
              name: modalTarget,
              url: window.location.href,
              type: 'POP_UP',
            },
          },
        };
        if (this.analytics.actionSource) {
          viewOpenedObj.actionSource = this.analytics.actionSource;
        }
        // newer segment event tracking using data team's taxonomy
        track('VIEW_OPENED', create(viewOpenedObj));

        this.$store.commit('analytics/viewOpened', null);
      }
    },
    'analytics.viewClosed': function (modalTarget) {
      if (modalTarget) {
        const viewOpenedObj = {
          view: {
            details: {
              name: modalTarget,
              url: window.location.href,
              type: 'POP_UP',
            },
          },
        };
        if (this.analytics.actionSource) {
          viewOpenedObj.actionSource = this.analytics.actionSource;
        }
        // newer segment event tracking using data team's taxonomy
        track('VIEW_CLOSED', create(viewOpenedObj));

        this.$store.commit('analytics/viewClosed', null);
      }
    },
    'analytics.submitClicked': function (modalTarget) {
      if (modalTarget) {
        const viewOpenedObj = {
          view: {
            details: {
              name: modalTarget,
              url: window.location.href,
              type: 'POP_UP',
            },
          },
        };
        if (this.analytics.actionSource) {
          viewOpenedObj.actionSource = this.analytics.actionSource;
        }
        // newer segment event tracking using data team's taxonomy
        track('SUBMIT_CLICKED', create(viewOpenedObj));

        this.$store.commit('analytics/submitClicked', null);
      }
    },
  },
  async mounted() {
    setupExperimentTracking();
    await setupInMarketTracking(getCookie, setCookie);
    if (Object.keys(EXPERIMENTS_CONFIG).length !== 0) {
      this.setupExperimentData(EXPERIMENTS_CONFIG);
    }
  },
  methods: {
    async getSignupEventData() {
      let signupEventObj;
      // if click on a social button
      if (this.form.social) {
        signupEventObj = {
          label: this.form.social,
          source: this.form.formId,
          signup: {
            type: getSignupType(this.form.social),
          },
        };
      } else {
        const userObj = {};

        signupEventObj = {
          deputy_email_flag: this.form.isDeputyEmail,
          signup: {},
          source: this.form.formId,
        };

        const emailHashSha512 = await getEmailHashSha512(this.form.email);
        if (emailHashSha512) {
          userObj.emailSha512 = emailHashSha512;
        }

        const emailDomain = getEmailDomain(this.form.email);
        if (emailDomain) {
          userObj.emailDomain = emailDomain;
        }

        signupEventObj.signup.type = getSignupType();
        signupEventObj.signup.user = userObj;

        signupEventObj.context = {
          user: { ...userObj },
        };
        signupEventObj.context.user.deputyEmailFlag = this.form.isDeputyEmail;
        delete signupEventObj.context.user.emailDomain;
      }

      if (this.analytics.actionSource) {
        signupEventObj.signup.actionSource = this.analytics.actionSource;
      }

      return create(signupEventObj);
    },
    setupExperimentData(experimentConfig) {
      const experimentObj = getExperimentDataFromCampaign(
        experimentConfig,
        this.$nuxt.context.route.query,
        this.$nuxt.context.route.path
      );
      if (experimentObj) {
        const experimentEventObj = {
          experiment: experimentObj,
        };
        createCookie(
          experimentConfig.COOKIE_NAME,
          JSON.stringify(experimentEventObj),
          30
        );
      }
    },
  },
};
