import Vue from 'vue';

Vue.mixin({
  methods: {
    prettyWrap: (content, words = 3) => {
      const totalWord = content.split(' ');
      const totalLength = totalWord.length;
      if (totalLength > 4) {
        // check if the text found does include "&" or any number with less than 3 digits, in this case we add an extra word
        // ex: to display correctly "engagement & performance" and not "& performance"
        // ex: to display correctly "with 10+ reviews" and not "10+ reviews" on the next line
        const firstWord = totalWord[totalLength - words];
        const precedingWord = totalWord[totalLength - (words + 1)];
        if (firstWord.match(/^(&|\b[0-9]{1,3}\b)/)) {
          words++;
        } else if (precedingWord === '&') {
          // if the preceding word was a "&" it means we cut a important word so we make sure to add in onto the next line
          words += 2;
        }
        let regex = '\\S+';
        for (let i = 0; i < words - 1; i++) {
          regex += '\\s+\\S+';
        }
        regex += '$';
        const contentFound = content.match(new RegExp(regex));
        if (contentFound) {
          content = content.replace(
            contentFound[0],
            `<span class="no-wrap">${contentFound[0]}</span>`
          );
        }
      }

      return content;
    },
  },
});
