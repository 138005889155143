const MAX_TRIES = 800;
const MAX_TIME_MS = 30000;
const scriptPromise = {};
const scriptTriesCount = {};

/**
 * Advises on whether a script has been loaded within a certain elapsed time from page load.
 * Eg:
 * await waitForScript('analytics');
 * analytics();
 *
 * @param {string} scriptName The name of the script
 * @param {number} maxTries Max times it should check if the script has been loaded
 * @param {number} duration The duration between the tries
 * @return {Promise<*>} Whether the script has loaded or not
 */
export default async function waitForScript(
  scriptName,
  maxTries = MAX_TRIES,
  duration = MAX_TIME_MS
) {
  if (!scriptPromise.hasOwnProperty(scriptName)) {
    scriptTriesCount[scriptName] = 0;
    scriptPromise[scriptName] = new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (typeof window[scriptName] !== 'undefined') {
          resolve();
          clearInterval(interval);
        }
        if (scriptTriesCount[scriptName] > MAX_TRIES) {
          reject(new Error(`waitForScript: "${scriptName}" max tries reached`));
          clearInterval(interval);
        }
        scriptTriesCount[scriptName]++;
      }, duration / maxTries);
    });
  }
  return scriptPromise[scriptName];
}
