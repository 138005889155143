import { getCookie, createCookie } from '~/utils/cookieHelpers';

const FREEMIUM_COOKIE_NAME = 'dp_freemium';
const FREEMIUM_COOKIE_LENGTH_DAYS = 30;

export default {
  methods: {
    computeFreemiumEligibility(ipCountry) {
      if (ipCountry !== 'us' || this.$store.state.region !== 'us') {
        return;
      }

      if (Boolean(getCookie(FREEMIUM_COOKIE_NAME))) {
        return;
      }

      createCookie(FREEMIUM_COOKIE_NAME, '1', FREEMIUM_COOKIE_LENGTH_DAYS);
    },
    showFreemiumIfEligible(ipCountry) {
      if (ipCountry !== 'us' || this.$store.state.region !== 'us') {
        return;
      }

      if (Boolean(getCookie(FREEMIUM_COOKIE_NAME))) {
        this.$store.commit('triggerFreemium', true);
      }
    },
  },
};
