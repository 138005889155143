import { onDemand } from '~/utils/onDemand';

export default function eHawkTalonInit(formId) {
  if (typeof document === 'undefined') {
    return;
  }
  const EHAWK_TALON_SETTINGS_ID = 'ehawk-talon-settings';

  const ehawkTalonSettings = document.getElementById(EHAWK_TALON_SETTINGS_ID);
  if (ehawkTalonSettings) {
    ehawkTalonSettings.remove();
  }

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.id = EHAWK_TALON_SETTINGS_ID;
  const code = `
        var eHawkTalonSettings = {
          bind: {
            OutId: 'talon-${formId}',
          }
        };
      `;
  try {
    s.appendChild(document.createTextNode(code));
    document.body.appendChild(s);
  } catch (e) {
    s.text = code;
    document.body.appendChild(s);
  }

  // eslint-disable-next-line new-cap
  const loader = new onDemand('/scripts/e-hawk-talon.js');
  loader.load();
}
