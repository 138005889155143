import { getCookie } from './cookieHelpers';
import waitForScript from './waitForScript';

const eventLabels = {
  // LOGIN/SIGNUP
  LOGIN_STARTED: {
    gtm: 'deputy.loginStarted',
    segment: 'LOGIN_STARTED',
  },
  SIGNUP_STARTED: {
    gtm: 'deputy.signupStarted',
    segment: 'SIGNUP_STARTED',
  },
  SIGNUP_ATTEMPT: {
    gtm: 'deputy.signupAttempt',
    segment: 'SIGNUP_USER_CREATE_REQUESTED',
  },
  SIGNUP_SUCCESS: {
    gtm: 'deputy.signupSuccess',
    segment: 'SIGNUP_ONCE_USER_CREATED_ACKNOWLEDGED',
  },
  SIGNUP_EXISTING: {
    gtm: 'deputy.existingAccount',
    segment: 'SIGNUP_ONCE_USER_EXISTING_ACCOUNT_ACKNOWLEDGED',
  },
  SIGNUP_FAILURE: {
    gtm: 'deputy.signupFail',
    segment: 'SIGNUP_USER_CREATE_FAILURE_ACKNOWLEDGED',
  },

  // MODAL
  VIEW_OPENED: {
    segment: 'VIEW_OPENED',
  },
  VIEW_CLOSED: {
    segment: 'VIEW_CLOSED',
  },
  SUBMIT_CLICKED: {
    segment: 'SUBMIT_CLICKED',
  },
  'Video modalOpened': {
    segment: 'Video modalOpened',
  },
  'Video modalClosed': {
    segment: 'Video modalClosed',
  },

  // FORM
  LEAD_SUBMITTED: {
    gtm: 'deputy.leadSubmission',
    segment: 'LEAD_SUBMITTED',
  },
  DOWNLOAD_FORM_SUBMITTED: {
    gtm: 'deputy.downloadFormSubmitted',
  },

  // GEO POPUP
  NOTIFICATION_ISSUED: {
    segment: 'NOTIFICATION_ISSUED',
  },
  NOTIFICATION_RESOLVED: {
    segment: 'NOTIFICATION_RESOLVED',
  },

  // EXPERIMENTS
  EXPERIMENT_STARTED: {
    segment: 'EXPERIMENT_STARTED',
  },
};

let inMarketToken = null;

/**
 * Create a new event data object for our analytics submissions. The below
 * properties are found as being common across all events.
 *
 * @see https://docs.google.com/spreadsheets/d/15KbqZ2PCaWqq8gAOzSy7Kh_FeTp1WOUTtguYfqtooCM/edit#gid=0
 *
 * @param {Object} obj extra event data to be merged into the final object
 * @return {Object} final event data object
 */
export function create(obj) {
  if (!obj.hasOwnProperty('context')) {
    obj.context = {};
  }

  // defaults
  obj.screen = 'WEBSITE';
  obj.context.application = isMobile() ? 'MOBILE_WEBSITE' : 'WEBSITE';

  if (
    typeof window.abTesting !== 'undefined' &&
    window.abTesting.hasOwnProperty('platform')
  ) {
    obj.context.abTesting = window.abTesting;
  }

  if (typeof window.personalisation !== 'undefined' && window.personalisation) {
    obj.context.personalisation = window.personalisation;
  }

  if (typeof window.enrichment !== 'undefined' && window.enrichment) {
    obj.context.enrichment = window.enrichment;
  }

  return obj;
}

/**
 * Make a Segment.identify() call to stitch together our user's browser
 * instances with their server account.
 *
 * @param {UUID} id user's Deputy account ID
 * @param {Object} obj event data object to send to Segment
 */
export function identify(id, obj) {
  try {
    window.analytics.identify(id, obj);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Segment error', error);
  }
}

/**
 * Track an analytics event to our common suites
 *
 * @param {String} eventName a string name that matches a key from our eventLabels object
 * @param {Object} eventObj the event data object to send to our analytics suites
 */
export async function track(eventName, eventObj) {
  // Dispatch custom event to allow Launch to hook into our events
  window.dispatchEvent(new CustomEvent(eventName, { detail: eventObj }));

  if (eventName in eventLabels) {
    // GTM
    if ('gtm' in eventLabels[eventName]) {
      const gtm = { event: eventLabels[eventName].gtm };
      try {
        window.dataLayer.push({ ...gtm, ...eventObj });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    // Segment
    try {
      const optionsObj = getTrackOptions();
      eventObj = { name: eventLabels[eventName].segment, ...eventObj };

      // we delete this flag before firing Segment event as it belongs to GTM only
      if (eventObj.hasOwnProperty('deputy_email_flag')) {
        delete eventObj['deputy_email_flag'];
      }
      // now that segment is lazy loading, there could be cases where we send a
      // `track` event on page load. (like NOTIFICATION_ISSUED (geo-popup or alert))
      // In that case we just have to make sure segment got time to load before.
      // On all pageView events coming from 3rd Party, Segment is not lazy loaded
      // but concurrency can happen. During testing it happens within 2-8ms
      // but I set up to 2000ms (2s) as a precaution
      if (typeof window.analytics === 'undefined') {
        $nuxt.$store.commit('setLoadAssets', 'segment');
        await waitForScript('analytics');
      }
      window.analytics.track(
        eventLabels[eventName].segment,
        eventObj,
        optionsObj
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  } else {
    throw new Error(`'${eventName}' is an invalid event name.`);
  }
}

// Todo this function does not seems in use anymore WWW-783
export function setupExperimentTracking() {
  window.trackExperiment = function () {
    window.analytics.track(
      'VIEW_OPENED',
      create({
        view: {
          details: {
            name: window.location.hostname + window.location.pathname,
            url: window.location.href,
            type: 'URL',
          },
        },
      })
    );
  };
}

/**
 * This setup the foundation for the inMarket tracking pixel
 * It will look if a token has been provided as part of the url and
 * store it in a cookie to be use during signup
 *
 * @param {Function} getCookie to track
 * @param {Function} setCookie to track
 */
export async function setupInMarketTracking(getCookie, setCookie) {
  inMarketToken = getCookie('inMarketToken');
  if (!inMarketToken) {
    const tokenFound = document.location.search.match(/(token=)([A-z0-9]*)/g);
    if (tokenFound) {
      inMarketToken = tokenFound[0].split('=')[1];
      await setCookie('inMarketToken', inMarketToken, 90);
      trackInMarketConversion('VISITED');
    }
  }
}

/**
 * Send a request to the inMarket url for reporting
 * <img src=”https://pixel.logtrackback.com/conversions?t=%{TOKEN}&a=[EVENT_NAME]”/>
 *
 * @param {String} eventName to track
 */
export function trackInMarketConversion(eventName) {
  if (inMarketToken) {
    try {
      const img = new Image();
      img.src = `https://pixel.logtrackback.com/conversions?t=${inMarketToken}&a=${eventName}`;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

const signupType = {
  UNKNOWN_SIGNUP_TYPE: 'UNKNOWN_SIGNUP_TYPE',
  SSO: 'SSO',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  EMAIL: 'EMAIL',
  INVITE_LINK: 'INVITE_LINK',
  APPLE: 'APPLE',
};

/**
 * Return the SignupType according to our protobuf settings
 * @see: https://github.com/DeputyApp/data-eng-events-spec/blob/65d5457c98face5cf8bc5cc1ff8a95a41a0c142a/proto/data/analytics/event/data/signup/signup_type.proto
 *
 * @param {String|null} social signup origin (Eg: Google, Facebook)
 * @return {String} type
 */
export function getSignupType(social = null) {
  if (!social) {
    return signupType.EMAIL;
  }

  social = social.toUpperCase();
  if (signupType.hasOwnProperty(social)) {
    return signupType[social];
  }
}

/**
 * Generate Sha512 hash from email address
 *
 * @param {String} emailAddress user email address
 * @return {String|null} Sha512 hash
 */
export async function getEmailHashSha512(emailAddress) {
  if (typeof window.crypto !== 'undefined') {
    return await window.crypto.subtle
      .digest('SHA-512', new TextEncoder('utf-8').encode(emailAddress))
      .then(buf => {
        return Array.prototype.map
          .call(new Uint8Array(buf), x => ('00' + x.toString(16)).slice(-2))
          .join('');
      });
  }
  return null;
}

/**
 * Get the domain from the email address
 *
 * @param {String} emailAddress email address
 * @return {String|null} domain
 */
export function getEmailDomain(emailAddress) {
  return emailAddress.substring(emailAddress.lastIndexOf('@') + 1);
}

/**
 * Generate the time in the right format for the event
 * Eg: "2022-02-15T04:42:12Z"
 *
 * @return {String} the formatted time
 */
export function getEventTime() {
  return new Date().toISOString().split('.')[0] + 'Z';
}

/**
 * Get Segment's track options object (mainly for adding integrations)
 * @see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/ajs-classic/#track
 *
 * @return {Object|null} trackOptions object
 */
export function getTrackOptions() {
  // Add GA clientId to integrate with Segment
  // @see: https://segment.com/docs/connections/destinations/catalog/google-analytics/#passing-cookies-from-universal-analytics
  if (typeof window.GAClientId !== 'undefined') {
    return {
      context: {
        integrations: {
          'Google Analytics': {
            clientId: window.GAClientId,
          },
        },
      },
    };
  } else {
    return null;
  }
}

/**
 * Get experiment data from campaign
 *
 * @param {Object} experimentConfig Experiment config data
 * @param {Object} queryParams Query params from the url
 * @param {String} path The url path
 * @return {Object|null} The experiment data
 */
export function getExperimentDataFromCampaign(
  experimentConfig,
  queryParams,
  path
) {
  const campaignName = queryParams?.utm_campaign;

  if (campaignName !== experimentConfig.CAMPAIGN_NAME) {
    return null;
  }

  const variation = path.includes(experimentConfig.VARIATION_LANDING_PATH)
    ? experimentConfig.VARIATION_NAME
    : experimentConfig.CONTROL_NAME;

  return {
    name: campaignName,
    variation: variation,
  };
}

/**
 * Track experiment based on its cookie
 *
 * @param {Object} cookieName Experiment config data
 */
export function trackExperimentByCookie(cookieName) {
  const experimentCookie = getCookie(cookieName);
  if (!experimentCookie) {
    return;
  }

  try {
    const experimentEventObj = JSON.parse(experimentCookie) || {};
    if (
      !experimentEventObj?.experiment ||
      !experimentEventObj?.experiment?.name ||
      !experimentEventObj?.experiment?.variation
    ) {
      return;
    }

    track('EXPERIMENT_STARTED', create(experimentEventObj));
  } catch (e) {
    console.error('error parsing experiment cookie', e);
  }
}

/**
 * Check if the browser is being accessed from a mobile device
 *
 * @return {boolean} whether is mobile or not
 */
export function isMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}

/**
 * Check if the user is a Deputy customer
 *
 * @return {boolean} whether is Deputy customer or not
 */
export function checkIfDeputyCustomer() {
  return Boolean(getCookie('dp_logged_in') || getCookie('dp_signed_up'));
}

/**
 * Check if the user is a Paid customer
 *
 * @return {boolean} whether is Paid customer or not
 */
export function checkIfPaidCustomer() {
  return (
    Boolean(getCookie('dp_paid_customer')) &&
    getCookie('dp_paid_customer') !== '0'
  );
}
