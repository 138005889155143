import { mapGetters } from 'vuex';
import Airgap from '../utils/airgap';

export default {
  data() {
    return {
      appId: '82ugbhvuf32e',
    };
  },
  computed: {
    ...mapGetters({
      getDriftStatus: 'getDriftStatus',
    }),
  },
  watch: {
    init: function (status) {
      if (typeof window.drift === 'undefined' && status) {
        this.listenCookieConsent();
      }
    },
    getDriftStatus: function (status) {
      if (typeof window.drift === 'undefined' && !status) {
        this.listenCookieConsent();
      } else {
        if (typeof window.drift === 'function') {
          this.toggleDrift(status);
        }
      }
    },
  },
  methods: {
    listenCookieConsent() {
      Airgap.executeWhenConsentAccepted('Drift', this.initDrift)
    },
    initDrift(open = false) {      
      console.log('initDrift');
      const script = document.createElement('script');
      const e = 3e5;
      const n = Math.ceil(new Date() / e) * e;
      script.type = 'text/javascript';
      script.async = true;
      script.crossorigin = 'anonymous';
      script.src = `https://js.driftt.com/include/${n}/${this.appId}.js`;
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        const component = this;
        const drift = window.drift;
        drift.SNIPPET_VERSION = '0.3.1';
        drift.on('ready', function () {
          component.toggleDrift(open);
        });
        drift.load(this.appId);
      });
    },
    toggleDrift(hide) {
      const drift = window.drift.api.widget;
      hide ? drift.hide() : drift.show();
      this.$store.commit('setDriftIsOpen', !hide);
    },
  },
};
