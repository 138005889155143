import parseUrlRegion from '~/utils/parseUrlRegion';

export default {
  computed: {
    // Maps the article data to the required fields
    // in the resource cards component
    mapArticlesToCards() {
      return this.articles.map(blogPost => {
        return {
          _uid: blogPost.content._uid,
          title: blogPost.content.headline,
          image: blogPost.content.thumbnail,
          linkUrl: parseUrlRegion(blogPost.full_slug),
          ctaText: 'Read More',
        };
      });
    },
  },
};
