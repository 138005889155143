import { mapState } from 'vuex';

export default {
  data() {
    return {
      regions: {
        us: {
          currency: 'USD',
          symbol: '$',
          cents: 'c',
          pricingKeyPrefix: 'amer',
        },
        gb: {
          currency: 'GBP',
          symbol: '£',
          cents: 'p',
          pricingKeyPrefix: 'emea',
        },
        au: {
          currency: 'AUD',
          symbol: '$',
          cents: 'c',
          pricingKeyPrefix: 'apac',
        },
      },
      // these countries have VAT
      vatCountries: [
        'BE',
        'BG',
        'CZ',
        'DK',
        'DE',
        'EE',
        'IE',
        'EL',
        'ES',
        'FR',
        'HR',
        'IT',
        'CY',
        'LV',
        'LT',
        'LU',
        'HU',
        'MT',
        'NL',
        'AT',
        'PL',
        'PT',
        'RO',
        'SI',
        'SK',
        'FI',
        'SE',
      ],
      annualPlan: false,
      plan: 'premium',
    };
  },
  computed: {
    ...mapState({
      costs: state => state.costs,
      addOnCosts: state => state.addOnCosts,
      country: state => state.pricingCountry,
    }),
    regional() {
      if (['au', 'gb'].includes(this.country)) {
        return this.regions[this.country];
      }
      return this.regions['us'];
    },
    pricingKey() {
      const pricingKeySuffix =
        this.plan === 'premium' ? '_monthly' : `_${this.plan}`;
      return `${this.regional.pricingKeyPrefix}_cost_per_user${pricingKeySuffix}`;
    },
    price() {
      return this.plan === 'freemium' ? 'Free' : this.costs[this.pricingKey];
    },
    flexiInactiveUserCost() {
      return this.costs[
        `${this.regional.pricingKeyPrefix}_cost_per_inactive_user_flexi`
      ];
    },
    pricingUrl() {
      const regionPrefix = ['au', 'gb'].includes(this.region)
        ? `/${this.region}`
        : '';
      return `${regionPrefix}/pricing`;
    },
  },
  methods: {
    getDisplayableAnnualTerms(plan) {
      if (this.annualPlan) {
        const annualPrice = this.formatPrice(
          this.costs[`${this.regional.pricingKeyPrefix}_cost_per_user`]
        );
        return `Or ${annualPrice} ${
          this.regional.currency
        } per user per month <br /> ${
          this.costs['tax_terms_annually']
        } ${this.findTaxTerm(plan)} - ${
          this.costs[
            `${this.regional.pricingKeyPrefix}_tax_terms_annually_saving`
          ]
        }`;
      }
      return null;
    },
    formatPrice(price, addCommas = false) {
      if (price === 'tbd') {
        return '';
      }

      let priceOutput = price;

      if (addCommas) {
        priceOutput = Number(price).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });
      } else if (price.includes('.')) {
        priceOutput = Number(price).toFixed(2);
      }

      if (price.toString().includes('.')) {
        const priceNumeric = Number(price);
        if (priceNumeric < 1) {
          return priceNumeric * 100 + this.regional.cents;
        } else {
          return this.regional.symbol + priceOutput;
        }
      }
      return this.plan === 'freemium'
        ? 'Free'
        : this.regional.symbol + priceOutput;
    },
    removePriceFormat(priceFormatted) {
      return priceFormatted.replace(/[^0-9.]/g, '');
    },
    findTaxTerm(plan) {
      let taxTermsPrefix;
      if (this.vatCountries.includes(this.country.toUpperCase())) {
        taxTermsPrefix = 'emea';
      } else {
        taxTermsPrefix = this.regional.pricingKeyPrefix;
      }
      return (
        this.costs[`${taxTermsPrefix}_tax_${plan}`] ||
        this.costs[`${taxTermsPrefix}_tax`]
      );
    },
    getDisplayableTerms(plan) {
      if (this.plan === 'enterprise') {
        return 'custom pricing for <br /> Enterprise clients';
      }
      if (this.plan === 'freemium') {
        return `${this.costs['tax_terms_freemium']}`;
      }
      if (
        this.plan === 'employee_onboarding' ||
        this.plan === 'hr_addon' ||
        this.plan === 'hr_flexi'
      ) {
        return `${
          this.costs[`${this.regional.pricingKeyPrefix}_terms_${plan}`]
        }`;
      }
      return `${this.costs['tax_terms_monthly']} <br /> ${
        this.regional.currency
      } ${this.findTaxTerm(plan)}`;
    },
    getHidePriceMessage(price) {
      if (
        price === 'tbd' &&
        this.costs.hasOwnProperty(
          `${this.regional.pricingKeyPrefix}_hide_price_msg`
        )
      ) {
        return this.costs[`${this.regional.pricingKeyPrefix}_hide_price_msg`];
      }
      return null;
    },
    getAddOnPrice(addOnPlan) {
      return this.formatPrice(this.addOnCosts[`${this.regional.pricingKeyPrefix}_cost_${addOnPlan}_addon`] || '0');
    },
    getAddOnTax(addOnPlan) {
      return this.addOnCosts[`${this.regional.pricingKeyPrefix}_tax_${addOnPlan}_addon`];
    },
    getAddOnTerms(addOnPlan) {
      return this.addOnCosts[`${this.regional.pricingKeyPrefix}_terms_${addOnPlan}_addon`];
    }
  },
};
