export const state = () => ({
  loginStarted: {
    status: false,
    source: 'unknown',
  },
  signupStarted: false,
  signupAttempt: false,
  signupFailed: {
    status: false,
    error: {},
  },
  viewOpened: null,
  viewClosed: null,
  submitClicked: null,
  signupDone: {
    user: null,
    action: null,
    status: null,
  },
  actionSource: null,
  notificationAction: {
    name: null,
    country: null,
    notification_message: null,
    notification_action: null,
  },
});

export const mutations = {
  // EVENTS
  signupStarted(state, payload) {
    state.signupStarted = payload;
  },
  signupFailed(state, payload) {
    state.signupFailed = payload;
  },
  signupAttempt(state, payload) {
    state.signupAttempt = payload;
  },
  setSignupResponse(state, payload) {
    state.signupDone = payload;
  },
  viewOpened(state, payload) {
    state.viewOpened = payload;
  },
  viewClosed(state, payload) {
    state.viewClosed = payload;
  },
  submitClicked(state, payload) {
    state.submitClicked = payload;
  },

  // DATA
  setActionSource(state, actionSource) {
    state.actionSource = actionSource;
  },

  // GEO-POPUP
  // Possible values for action: click/close/show
  notificationAction(state, payload) {
    state.notificationAction = payload;
  },
};
