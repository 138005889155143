import { checkGeoPopupCookie } from '~/utils/geoPopup';
import freemiumSetup from './freemiumSetup';

export default {
  mixins: [freemiumSetup],
  created() {
    this.$store.commit('setIpCountry', this.$store.state.region);
  },
  beforeMount() {
    const cloudflareCountry = window.cfIpCountry;
    const debugCountryMatches = window.location.search.match(
      /country_code=([a-zA-z]{2})/
    );
    const debugCountry = debugCountryMatches
      ? debugCountryMatches[1].toLowerCase()
      : null;
    const ipCountry =
      debugCountry || cloudflareCountry || this.$store.state.region;

    // GDPR EU
    const EU_REGIONS = [
      'ad',
      'al',
      'am',
      'at',
      'ba',
      'be',
      'by',
      'bg',
      'ch',
      'cy',
      'cz',
      'dk',
      'de',
      'ee',
      'es',
      'fi',
      'fo',
      'fr',
      'gb',
      'ge',
      'gi',
      'hu',
      'hr',
      'ie',
      'is',
      'it',
      'lv',
      'lt',
      'lu',
      'mc',
      'mk',
      'mt',
      'nl',
      'no',
      'po',
      'pt',
      'ro',
      'ru',
      'se',
      'si',
      'sk',
      'sm',
      'tr',
      'ua',
      'va',
    ];

    if (EU_REGIONS.includes(ipCountry)) {
      this.$store.commit('triggerGDPR', true);
    }

    this.computeFreemiumEligibility(ipCountry);
    this.showFreemiumIfEligible(ipCountry);

    // if the ipCountry is not au or gb we will always default it to us
    const regions = ['au', 'gb'];
    const ipCountryDefault = regions.includes(ipCountry) ? ipCountry : 'us';

    this.$store.commit('setIpCountry', ipCountryDefault);

    // if the ipCountryDefault is not the same as the
    // regionalised website version we need to show the geo-popup
    if (ipCountryDefault !== this.$store.state.region) {
      checkGeoPopupCookie();
    }

    // allow specific price being displayed based on the country ip
    // if the user is from New Zealand we want to show the AU pricing
    const pricingCountry = ipCountry === 'nz' ? 'au' : ipCountryDefault;

    this.$store.commit('setPricingCountry', pricingCountry);
  },
};
