const Airgap = (() => {
  let servicesMeta = {};
  let currentConsent = [];
  let airgapInitialized = false;
  let isConfirmed = false;

  const updateState = async () => {
    const defaultPurposes = ['Essential'];
    const metadata = await window.airgap.getMetadata();
    const { confirmed, purposes } = window.airgap.getConsent();
    servicesMeta = metadata.services;
    currentConsent = Object.keys(purposes).filter(purposeKey => {
      if(purposes[purposeKey]) {
        return purposeKey;
      }
    }).concat(defaultPurposes);
    airgapInitialized = true;
    isConfirmed = confirmed;
  };

  /**
   * Check if the user has accepted the consent (dataFlows and cookies) for a specific service
   * @param {string} serviceName - The name of the service
   * @returns {boolean} - True if all dataFlows and cookies are accepted, otherwise false
   */
  const checkServiceConsent = (serviceName) => {
    const serviceData = servicesMeta.find(service => service.title === serviceName);
    if (!serviceData) {
      return false;
    }

    const acceptAllDataFlows = serviceData.dataFlows.every(dataFlow => {
      return dataFlow.trackingPurposes.every(purpose => currentConsent.includes(purpose));
    });

    const acceptAllCookies = serviceData.cookies.every(cookie => {
      return cookie.trackingPurposes.every(purpose => currentConsent.includes(purpose));
    });

    return acceptAllDataFlows && acceptAllCookies;
  };

  /**
   * Check if the user has accepted the cookies for a specific service
   * @param {string} serviceName - The name of the service
   * @param {Array} cookies - An array of cookie names to check, if empty all cookies will be checked
   * @returns {boolean} - True if all cookies are accepted, otherwise false
   */
  const checkServiceCookies = (serviceName, cookies = []) => {
    const serviceData = servicesMeta.find(service => service.title === serviceName);
    if (!serviceData) {
      return false;
    }

    let cookiesToCheck = [];
    if (cookies && Array.isArray(cookies) && cookies.length > 0) {
      cookiesToCheck = serviceData.cookies.filter(cookie => cookies.includes(cookie.name));
    } else {
      cookiesToCheck = serviceData.cookies;
    }

    const result = cookiesToCheck.every(cookie => {
      return cookie.trackingPurposes.every(purpose => currentConsent.includes(purpose));
    });

    return result;
  };

  /**
   * Listen for the consent update event and execute the callback function when the consent is accepted
   * @param {Function} callbackFunc - The function to execute when the consent is accepted
   * @returns {void}
   */
  const listenForConsentUpdate = (callbackFunc) => {
    if (!window.airgap) return;

    window.airgap.ready(async (airgap) => {
      if (!airgapInitialized) {
        await updateState();
      }

      if (isConfirmed) {
        callbackFunc();
        return;
      }

      airgap.addEventListener('consent-change', async () => {
        await updateState();
        callbackFunc();
      }, { once: true });
    })
  };

  /**
   * Execute a callback function when the consent is accepted for a specific service
   * @param {string} serviceName - The name of the service
   * @param {Function} callbackFunc - The function to execute when the consent is accepted
   * @returns {void}
   */
  const executeWhenConsentAccepted = (serviceName, callbackFunc) => {
    listenForConsentUpdate(() => {
      const isConsentAccepted = checkServiceConsent(serviceName);
      if (isConsentAccepted) {
        callbackFunc();
      }
    });
  };

  /**
   * Execute a callback function when the cookies are accepted for a specific service
   * @param {string} serviceName - The name of the service
   * @param {Array} cookies - An array of cookie names to check, if empty all cookies will be checked
   * @param {Function} callbackFunc - The function to execute when the cookies are accepted
   * @returns {void}
   */
  const excuteWhenCookiesAccepted = (serviceName, cookies = [], callbackFunc) => {
    listenForConsentUpdate(() => {
      const isConsentAccepted = checkServiceCookies(serviceName, cookies);
      if (isConsentAccepted) {
        callbackFunc();
      }
    });
  };

  return {
    executeWhenConsentAccepted,
    excuteWhenCookiesAccepted
  };
})();

export default Airgap;
