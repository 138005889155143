import Vue from 'vue';

Vue.filter('prettyDate', rawDate => {
  // replace() here is used to make sure Safari is parsing correctly the date
  const date = new Date(rawDate.toString().replace(/\s/, 'T'));
  const day = date.toLocaleString('default', {
    day: 'numeric',
  });
  const month = date.toLocaleString('default', {
    month: 'long',
  });
  const year = date.toLocaleString('default', {
    year: 'numeric',
  });
  return `${month} ${day}, ${year}`;
});

Vue.filter('noTime', rawDate => {
  return rawDate.slice(0, 10);
});

export function capitalize(value) {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

Vue.filter('capitalize', capitalize);
