/**
 * @fileoverview This file defines theme-related constants and Vue mixins for managing theme colors in a Vue.js application.
 *
 * The file includes:
 * - Theme constants
 * - Theme color mappings
 * - Vue mixin for theme-related properties and computed methods
 *
 * The mixin provides computed properties and methods to retrieve and generate CSS class strings based on the current theme.
 *
 * @module componentTheme
 */
import Vue from 'vue';

export const THEME = {
  WHITE: 'white',
  YELLOW: 'yellow',
  PEACH: 'peach',
  AZURE: 'azure',
  PINK: 'pink',
  PURPLE: 'purple',
  ENTERPRISE: 'enterprise',
};

export const CMS_THEMES = {
  PURPLE: 'purple',
  PINK: 'pink',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  GREEN: 'green',
  BLUE: 'blue',
};

const themes = {
  [THEME.WHITE]: 'grey-10',
  [THEME.YELLOW]: 'yellow-10',
  [THEME.PEACH]: 'peach-10',
  [THEME.AZURE]: 'azure-60',
  [THEME.PINK]: 'pink-50',
  [THEME.PURPLE]: 'purple-50',
  [THEME.ENTERPRISE]: 'forest-50',
};

const textThemes = {
  [THEME.WHITE]: 'azure-60',
  [THEME.YELLOW]: 'azure-60',
  [THEME.PEACH]: 'azure-60',
  [THEME.AZURE]: 'grey-10',
  [THEME.PINK]: 'grey-10',
  [THEME.PURPLE]: 'grey-10',
  [THEME.ENTERPRISE]: 'grey-10',
};

const textTagThemes = {
  [THEME.WHITE]: 'grey-10',
  [THEME.YELLOW]: 'grey-10',
  [THEME.PEACH]: 'grey-10',
  [THEME.AZURE]: 'azure-60',
  [THEME.PINK]: 'azure-60',
  [THEME.PURPLE]: 'grey-10',
  [THEME.ENTERPRISE]: 'azure-60',
};

const bgTagThemes = {
  [THEME.WHITE]: 'pink-50',
  [THEME.YELLOW]: 'pink-50',
  [THEME.PEACH]: 'pink-50',
  [THEME.AZURE]: 'purple-30',
  [THEME.PINK]: 'pink-30',
  [THEME.PURPLE]: 'purple-40',
  [THEME.ENTERPRISE]: 'peach-20',
};

const textTagLineThemes = {
  [THEME.WHITE]: 'pink-40',
  [THEME.YELLOW]: 'pink-40',
  [THEME.PEACH]: 'pink-40',
  [THEME.AZURE]: 'peach-30',
  [THEME.PINK]: 'peach-20',
  [THEME.PURPLE]: 'peach-20',
  [THEME.ENTERPRISE]: 'peach-20',
};

Vue.prototype.$gooseThemes = themes;

Vue.mixin({
  props: {
    theme: {
      type: String,
      validator: t => themes.hasOwnProperty(t.toLowerCase()),
      default: 'white',
    },
  },
  computed: {
    /**
     * This is the main color for a theme. It is typically used as the background color.
     * @return {String} name of the variable e.g. grey-10
     */
    themeColor() {
      return themes[this.theme.toLowerCase()];
    },
    /**
     * Retrieves the text theme color based on the current theme.
     *
     * @return {string} The color associated with the current theme. e.g. grey-10
     */
    textThemeColor() {
      return textThemes[this.theme.toLowerCase()];
    },
    textThemeColorClass() {
      if (!this.textThemeColor) {
        return '';
      }

      return `text-${this.textThemeColor}`;
    },
    bgThemeColorClass() {
      if (!this.themeColor) {
        return '';
      }

      return `bg-${this.themeColor}`;
    },
    bgTagColorClass() {
      const bgTagColor = bgTagThemes[this.theme.toLowerCase()];
      if (!bgTagColor) {
        return '';
      }

      return `bg-${bgTagColor}`;
    },
    textTagColorClass() {
      const textTagColor = textTagThemes[this.theme.toLowerCase()];
      if (!textTagColor) {
        return '';
      }
      return `text-${textTagColor}`;
    },
    textTagLineColorClass() {
      const textTagLineColor = textTagLineThemes[this.theme.toLowerCase()];
      if (!textTagLineColor) {
        return '';
      }

      return `text-${textTagLineColor}`;
    },
  },
  methods: {
    /**
     * Returns the background theme color class based on the provided theme.
     *
     * @param {string} [theme=''] - The theme name to get the background color class for.
     * @return {string} The background theme color class, or an empty string if the theme is not found.
     */
    getBgThemeColorClass(theme = '') {
      const currentTheme = themes[theme.toLowerCase()];
      if (!currentTheme) {
        return '';
      }

      return `bg-${currentTheme}`;
    },
  },
});
