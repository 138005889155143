import { mapState } from 'vuex';
import GContainer from '../components/goose/g-container';
import GSpacer from '../components/goose/g-spacer';
import GFriendlyId from '../components/goose/g-friendly-id/g-friendly-id';
import { THEME } from '~/plugins/componentTheme';

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      default: '',
    },
  },
  components: {
    GContainer,
    GSpacer,
    GFriendlyId,
  },
  computed: {
    ...mapState({
      stats: state => state.stats,
    }),
    paddingTop() {
      if (typeof this.blok.padding_top !== 'undefined') {
        return this.blok.padding_top;
      }
      return null;
    },
    spacer_bottom() {
      if (typeof this.blok.spacer_bottom !== 'undefined') {
        return this.blok.spacer_bottom;
      }
      return '';
    },
    spacer_bottom_mobile() {
      if (
        typeof this.blok.spacer_bottom_mobile !== 'undefined' &&
        this.blok.spacer_bottom_mobile !== ''
      ) {
        return this.blok.spacer_bottom_mobile;
      }
      return undefined;
    },
    friendly_id() {
      if (typeof this.blok.friendly_id !== 'undefined') {
        return this.blok.friendly_id;
      }
      return '';
    },
    getRegion() {
      if (this.$store) {
        return this.$store.state.region;
      }
      return 'us';
    },
    getTheme() {
      return this.blok.theme || THEME.WHITE;
    },
  },
  methods: {
    interpolateStats(stringToInterpolate) {
      const regex = /\${[^{]+}/g;
      return stringToInterpolate.replace(regex, match => {
        const key = match.slice(2, -1).trim();
        return (
          this.stats[key] || `:: Key '${key}' does not exist in statistics ::`
        );
      });
    },
  },
};
