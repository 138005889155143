/**
 * Set a cookie asynchronously.
 *
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {int} exdays Number of days the cookie will expire
 * @return {boolean} Confirmation
 */
export function setCookie(cname, cvalue, exdays) {
  return new Promise((resolve, reject) => {
    const date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
    resolve(true);
  });
}

/**
 * Get a cookie value by its name.
 *
 * @param {string} cname Cookie name
 * @return {string|null} Cookie value
 */
export function getCookie(cname) {
  const name = cname + '=';
  const cookie = document.cookie.split(';');
  for (let i = 0; i < cookie.length; i++) {
    let c = cookie[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

/**
 * Create a cookie synchronously.
 *
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {int} exdays Number of days the cookie will expire
 * @return {boolean} Confirmation
 */
export function createCookie(cname, cvalue, exdays) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
  return true;
}
