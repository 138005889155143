/**
 * Account Signup
 *
 * This manages all of the functionality around the account signup forms
 * throughout the website. In particular, it has to submit the data to
 * once.deputy.com to create a new account, which then sends a new "welcome"
 * URL back to this script which consequently redirects the user to the page.
 * It also triggers some analytics events in Segment depending on success/fail
 * statuses that occur during the process.
 */

import { mapState } from 'vuex';
import { getCookie, setCookie } from '~/utils/cookieHelpers';
import axios from 'axios';
import { handleMarketoSignupForm } from '~/utils/marketo';
import { getRecaptchaSiteKey, getRecaptchaToken } from '~/utils/recaptcha';
import Airgap from '../utils/airgap';

export default {
  data() {
    return {
      DPReferrer: null,
      signupStarted: null,
      DPSignupPage: null,
    };
  },
  computed: {
    ...mapState({
      form: state => state.form,
      analytics: state => state.analytics,
    }),
  },
  watch: {
    'form.submitForm': async function (isFormSubmitted) {
      const anonymousUserId =
        typeof window.analytics?.user === 'function'
          ? window.analytics.user().anonymousId()
          : null;

      if (isFormSubmitted) {
        const formData = new FormData();
        formData.append('cmd', 'doSignUpOrLoginViaEmail');
        formData.append('FirstName', this.form.firstName);
        formData.append('LastName', this.form.lastName);
        formData.append('EmailAddress', this.form.email);
        formData.append('actionSource', this.analytics.actionSource);
        formData.append('SegmentAnonymousId', anonymousUserId);
        formData.append('DPReferrer', this.DPReferrer);
        formData.append('DPSignupPage', this.DPSignupPage);
        formData.append('DeputyCampaignCode', '');
        formData.append('abTesting', window.abTesting || []);
        formData.append('inMarket', getCookie('inMarketToken'));
        formData.append('starter', window.dptyStarterPlan || false);

        // eHawkTalon security score
        formData.append('talon', JSON.stringify(this.form.talon));

        const recaptchaSiteKey = getRecaptchaSiteKey();
        const recaptchaToken = await getRecaptchaToken(recaptchaSiteKey);
        if (recaptchaToken) {
          formData.append('RecaptchaToken', recaptchaToken);
        }

        const urlParams = new URLSearchParams(window.location.search);
        const isSignUpOpen = urlParams.get('signup') === 'open';
        let syntheticToken = '';
        if (isSignUpOpen) {
          syntheticToken = urlParams.get('synth');
        }

        this.$store.commit('analytics/signupAttempt', true);

        axios({
          method: 'post',
          url: 'https://once.deputy.com/my/post-signup.php',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            ...(syntheticToken && { 'Deputy-Synthetic': syntheticToken }),
          },
          data: formData,
        })
          .then(async response => {
            const hasErrors = await this.handleErrors(response.data);
            if (!hasErrors) {
              // NO SIGNUP ERROR FOUND => SIGNUP WILL CONTINUE
              const payload = {
                user: response.data.user,
                action: response.data.action,
                status: response.status,
              };
              this.$store.commit('analytics/setSignupResponse', payload);

              try {
                const marketoSignupFormId =
                  this.$store.state.form.marketoSignupFormId;
                const marketoSignupPayload = {
                  user: response.data.user,
                  // Spiralyze experiments tracking
                  spz_experiment1: this.form.spz_experiment1,
                  spz_experiment2: this.form.spz_experiment2,
                };
                handleMarketoSignupForm(
                  marketoSignupFormId,
                  marketoSignupPayload
                );
              } catch (e) {
                console.error('submit-signup-data-to-marketo-error', e);
              }

              // Sets a cookie so we know this user has signed up
              await setCookie('dp_signed_up', '1', 90);

              // Redirect to the provided once.deputy.com URL
              setTimeout(function () {
                // adding .top here because the signup form can be embedded in an iframe
                window.top.location = response.data.redirectUrl;
              }, 500);
            }
          })
          .catch(error => {
            if (error?.name === 'AxiosError') {
              const formattedError = {
                error: {
                  Api: error?.message ?? 'Generic API Error',
                },
              };
              this.handleErrors(formattedError);
            } else if (error instanceof TypeError) {
              const formattedError = {
                error: {
                  Generic: error?.message ?? 'Generic Error',
                },
              };
              this.handleErrors(formattedError);
            } else {
              this.handleErrors(error);
            }

            throw error;
          });
      }
    },
  },
  async mounted() {
    this.DPSignupPage = document.location.href;
    this.deputyReferrer();
  },
  methods: {
    deputyReferrer() {
      Airgap.excuteWhenCookiesAccepted('Deputy', ['DPReferrer'], this.setDeputyReferrer);
    },
    setDeputyReferrer() {
      console.log('setDeputyReferrer')
      this.DPReferrer = getCookie('DPReferrer');
      if (this.DPSignupPage.indexOf('utm_') > 1) {
        // If they're arriving from Google, update DPReferrer to include UTM
        // because we want to know about campaign value/success
        setCookie('DPReferrer', this.DPSignupPage, 90);
      } else if (!this.DPReferrer) {
        // Otherwise, if DPReferrer hasn't been set previously
        if (document.referrer) {
          setCookie('DPReferrer', document.referrer, 90);
        } else {
          setCookie('DPReferrer', this.DPSignupPage, 90);
        }
      }
    },
    handleErrors(data) {
      if (data && 'error' in data) {
        // SIGNUP ERROR FOUND => SIGNUP WILL STOP
        let rawErrorMessageFromApi = '';
        if (data.error.hasOwnProperty('EmailAddress')) {
          rawErrorMessageFromApi = `EmailAddress: ${data.error.EmailAddress}`;
          this.$store.commit('form/showError', 'email');
        } else if (
          data.error.hasOwnProperty('FirstName') ||
          data.error.hasOwnProperty('LastName')
        ) {
          const errorName = data.error.hasOwnProperty('FirstName')
            ? 'FirstName'
            : 'LastName';
          rawErrorMessageFromApi = `${errorName}: ${data.error[errorName]}`;
          this.$store.commit('form/showError', 'name');
        } else if (data.error.hasOwnProperty('RecaptchaToken')) {
          rawErrorMessageFromApi = `RecaptchaToken [DP002]: ${data.error.RecaptchaToken}`;
          this.$store.commit('form/showError', 'recaptcha');
        } else if (data.error.hasOwnProperty('Api')) {
          rawErrorMessageFromApi = `Api [DP003]: ${data.error.Api}`;
          this.$store.commit('form/showError', 'api');
        } else {
          rawErrorMessageFromApi = `Generic [DP001]: ${
            data?.error?.Generic ?? 'Generic Error'
          }`;
          this.$store.commit('form/showError', 'generic');
        }
        // reset the form so it can be submitted again
        this.$store.commit('form/submitForm', false);
        // send a tracking event
        this.$store.commit('analytics/signupFailed', {
          status: true,
          error: {
            message: rawErrorMessageFromApi,
          },
        });
        return true;
      }
      return false;
    },
  },
};
