export default function (context) {
  context.store.dispatch('modal/setModalData');
  context.store.dispatch('modal/setBlogModalData');
  context.store.dispatch('modal/setSignupData');
  context.store.dispatch('setStatsData');
  context.store.dispatch('setCostsData');
  context.store.dispatch('setAddOnCostsData');
  context.store.dispatch('setIndustryData');
  context.store.dispatch('setFiltersData');
  context.store.dispatch('setPlanData');
  context.store.dispatch('setIntegrationsData');
  context.store.dispatch('setComplianceStatesData');
}
