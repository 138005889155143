import Vue from 'vue';
import throttle from 'lodash/throttle';

const globalData = new Vue({
  data: {
    isMobileScreen: '',
    isTabletScreen: false,
    isMaxTabletScreen: false,
    isTabletLargeScreen: false,
    isMaxTabletLargeScreen: false,
    isDesktopScreen: false,
    screenListener: false,
  },
});

Vue.mixin({
  computed: {
    $isMobile() {
      return globalData.$data.isMobileScreen;
    },
    $isTablet() {
      return globalData.$data.isTabletScreen;
    },
    $isMaxTablet() {
      return globalData.$data.isMaxTabletScreen;
    },
    $isTabletLarge() {
      return globalData.$data.isTabletLargeScreen;
    },
    $isMaxTabletLarge() {
      return globalData.$data.isMaxTabletLargeScreen;
    },
    $isDesktop() {
      return globalData.$data.isDesktopScreen;
    },
  },
  beforeMount() {
    if (typeof window !== 'undefined') {
      if (!Vue.prototype.screenListener) {
        Vue.prototype.screenListener = true;
        window.addEventListener('resize', throttle(this.getScreenWidth, 200));
        this.getScreenWidth();
      }
    }
  },
  methods: {
    getScreenWidth() {
      globalData.$data.isMobileScreen = window.innerWidth < 768;
      globalData.$data.isTabletScreen = window.innerWidth >= 768;
      globalData.$data.isMaxTabletScreen = window.innerWidth <= 768;
      globalData.$data.isTabletLargeScreen = window.innerWidth >= 992;
      globalData.$data.isMaxTabletLargeScreen = window.innerWidth <= 992;
      globalData.$data.isDesktopScreen = window.innerWidth >= 1200;
    },
  },
});
