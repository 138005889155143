export function handleMarketoSignupForm(formId, signupData) {
  try {
    if (window.MktoForms2) {
      const vals = {
        Email: signupData.user.email,
        mktoFullName: signupData.user.displayName,
        FirstName: signupData.user.firstName,
        LastName: signupData.user.lastName,
        mktoOptIn: 'yes', // T&C checkbox
        spz_experiment1: signupData.spz_experiment1, // Spiralyze experiments tracking
        spz_experiment2: signupData.spz_experiment2, // Spiralyze experiments tracking
      };
      const marketoSignupForm = window.MktoForms2.getForm(formId);
      if (typeof marketoSignupForm !== 'undefined') {
        marketoSignupForm.vals(vals);
        MktoForms2.whenReady(function (form) {
          form.submit();
          console.log('marketo-signup-form-submitted');
        });
      } else {
        console.error('marketo-signup-form-not-found');
      }
    } else {
      console.error('marketo-signup-form-mktoforms2-not-loaded');
    }
  } catch (e) {
    console.error('marketo-signup-form-error', e);
  }
}
