import Vue from 'vue';

import CmsFaqCollection from '~/components/cms-faq-collection';
import CmsFeatureHeader from '~/components/cms-feature-header';
import CmsWorkplacesLogoBlock from '~/components/cms-workplaces-logo-block';
import CmsCtaDark from '~/components/cms-cta-dark';
const CmsTrust = () => import('~/components/cms-trust');
import CmsRelatedFeatures from '~/components/cms-related-features';
import CmsHeader from '~/components/cms-header';
const CmsFeatureCarousel = () => import('~/components/cms-feature-carousel');
import CmsQuote from '~/components/cms-quote';
import CmsInfographic from '~/components/cms-infographic';
import CmsResourceCards from '~/components/cms-resource-cards';
import CmsResourceCardsEditable from '~/components/cms-resource-cards-editable';
const CmsCards = () => import('~/components/cms-cards');
// import CmsCards from '~/components/cms-cards';
// import CmsRoiCalculator from '~/components/cms-roi-calculator';
const CmsRoiCalculator = () => import('~/components/cms-roi-calculator');
import CmsProductTour from '~/components/cms-product-tour';
import CmsRichtext from '~/components/cms-richtext';
import CmsBlogRichtext from '~/components/cms-blog-richtext';
import CmsFeatureHighlight from '~/components/cms-feature-highlight';
import CmsRelatedArticles from '~/components/cms-related-articles';
import CmsCompetitorComparison from '~/components/cms-competitor-comparison';
const CmsCardsThreeSpeakers = () =>
  import('~/components/cms-cards-three-speakers');
import CmsHeaderDarkOverhang from '~/components/cms-header-dark-overhang';
import TrainingHubHeader from '~/components/training/training-hub-header';
const CmsProblemsSolutionsCards = () =>
  import('~/components/cms-problems-solutions-cards');
import CmsLottieAnimation from '~/components/cms-lottie-animation';
import CroVideoTeaser from '~/components/cro/cro-video-teaser';
import CroWhitespace from '~/components/cro/cro-whitespace';
import CroIconTrio from '~/components/cro/cro-icon-trio';
import CmsBlogRawContent from '~/components/cms-blog-raw-content';
import CmsCtaLight from '~/components/cms-cta-light';
const CmsLeverDropdown = () => import('~/components/cms-lever-dropdown');
import CmsInsightsHeader from '~/components/cms-insights-header';
import CmsLogoDivider from '~/components/cms-logo-divider';
import CmsHeaderWithCarouselQuotes from '~/components/cms-header-with-carousel-quotes';
import CmsColourHeadingCards from '~/components/cms-colour-heading-cards';
import CmsCards4ColsIconsLinks from '~/components/cms-cards-4-cols-icons-links';
const Cms2ColsImgCopy = () => import('~/components/cms-2-cols-img-copy');
import CmsFullWidthMobileImage from '~/components/cms-full-width-mobile-image';
import CmsFullWidthImage from '~/components/cms-full-width-image';
import CmsQuoteWithImage from '~/components/cms-quote-with-image';
const CmsHeaderAnimation = () => import('~/components/cms-header-animation');
import CmsPricingOnPage from '~/components/cms-pricing-on-page';
import CmsTrustWithNumbers from '~/components/cms-trust-with-numbers';
import CmsSignupForm from '~/components/cms-signup-form';
import CmsRoundCardsLinks from '~/components/cms-round-cards-links';
import CmsColumnGroupedCards from '~/components/cms-column-grouped-cards';
import CmsSocialLinks from '~/components/cms-social-links';
const CmsAccordion2Col = () => import('~/components/cms-accordion-2-col');
import CmsTabCopyMedia from '~/components/cms-tab-copy-media';
import CmsCards4ColLinks from '~/components/cms-cards-4-col-links';
import CmsCardLandscapeRoundedCorners from '~/components/cms-card-landscape-rounded-corners';
import CmsCardOutlineSquiggle from '~/components/cms-card-outline-squiggle';
const CmsIntegrationsFilterCarousel = () =>
  import('~/components/cms-integrations-filter-carousel');
import CmsCardHighlightPrice from '~/components/cms-card-highlight-price';
import CmsPersonalisation from '~/components/cms-personalisation';
import CmsTrustCarouselQuote from '~/components/cms-trust-carousel-quote';
import CmsPricingFeatures from '~/components/cms-pricing-features';
import CmsStepByStep2Col from '~/components/cms-step-by-step-2-col';
import CmsAwardBadges from '~/components/cms-award-badges';
import CmsIllustrationCopyLink from '~/components/cms-illustration-copy-link';
import CmsCardToModalTarget from '~/components/cms-card-to-modal-target';
import BlogExitPopUpModal from '~/components/blog-exit-pop-up-modal';
const CmsPressCards = () => import('~/components/cms-press-cards');
import CmsMediaOnPage from '~/components/cms-media-on-page';
import CmsTestimonialWithFaq from '~/components/cms-testimonial-with-faq';
const CmsMarketoMediaForm = () => import('~/components/cms-marketo-media-form');
import CmsMarketoForm from '~/components/cms-marketo-form';
import SignupForm from '~/components/signup-form';
import BookAConsultation from '~/components/book-a-consultation';
import templateBlank from '~/components/template-blank';
import WidgetSignupForm from '~/components/widgets/widget-signup-form';
import CmsHeaderMediaHighlight from '~/components/cms-header-media-highlight';
const CmsLoginCarousel = () => import('~/components/cms-login-carousel');
import CmsIframePreview from '~/components/cms-iframe-preview';
import CmsLightBgTestimonial from '~/components/cms-light-bg-testimonial';
const CmsOnpageList = () => import('~/components/cms-onpage-list');
import CmsError from '~/components/cms-error';
import GeneralExitPopUpModal from '~/components/general-exit-pop-up-modal';
import CmsHeaderMediaCtas from '~/components/cms-header-media-ctas';
const CmsOnpageFormHighlight = () =>
  import('~/components/cms-onpage-form-highlight');
import CmsTable from '~/components/cms-table';
import ModalNewsletter from '~/components/modal-newsletter';
import CmsCardInline from '~/components/cms-card-inline';
import CmsCompanyRating from '~/components/cms-company-rating';
import CmsTrustWithWorkplaces from '~/components/cms-trust-with-workplaces';
import Cms2ColsScroll from '~/components/cms-2-cols-scroll';
import CmsButtonV2 from '~/components/cms-button-v2';
import GlossaryTerms from '~/components/glossary-terms/glossary-terms';
import CmsLazyImage from '~/components/cms-lazy-image';
import CmsTrustRadiusWidget from '~/components/cms-trust-radius-widget';
import CmsTag from '~/components/cms-tag';
import CmsAddOnPricing from '~/components/cms-add-on-pricing';
import CmsFullWidthMedia from '~/components/cms-full-width-media';
import CmsStats3Cols from '~/components/cms-stats-3-cols';
import CmsFullWidthQuote from '~/components/cms-full-width-quote';
import CmsFullWidthCarousel from '~/components/cms-full-width-carousel';
import CmsComparison2Cols from '~/components/cms-comparison-2-cols';
import CmsCardLaw from '~/components/cms-card-law';
import CmsDisclaimer from '~/components/cms-disclaimer';
import CmsStateList from '~/components/cms-state-list.vue';
import CmsHeaderSearchBox from '~/components/cms-header-searchbox';
import CmsNewsletterOpenModal from '~/components/cms-newsletter-open-modal';

Vue.component('cms-faq-collection', CmsFaqCollection);
Vue.component('cms-feature-header', CmsFeatureHeader);
Vue.component('cms-workplaces-logo-block', CmsWorkplacesLogoBlock);
Vue.component('cms-cta-dark', CmsCtaDark);
Vue.component('cms-trust', CmsTrust);
Vue.component('cms-related-features', CmsRelatedFeatures);
Vue.component('cms-header', CmsHeader);
Vue.component('cms-feature-carousel', CmsFeatureCarousel);
Vue.component('cms-quote', CmsQuote);
Vue.component('cms-infographic', CmsInfographic);
Vue.component('cms-resource-cards', CmsResourceCards);
Vue.component('cms-resource-cards-editable', CmsResourceCardsEditable);
Vue.component('cms-cards', CmsCards);
Vue.component('cms-roi-calculator', CmsRoiCalculator);
Vue.component('cms-product-tour', CmsProductTour);
Vue.component('cms-richtext', CmsRichtext);
Vue.component('cms-blog-richtext', CmsBlogRichtext);
Vue.component('cms-feature-highlight', CmsFeatureHighlight);
Vue.component('cms-related-articles', CmsRelatedArticles);
Vue.component('cms-competitor-comparison', CmsCompetitorComparison);
Vue.component('cms-cards-three-speakers', CmsCardsThreeSpeakers);
Vue.component('training-hub-header', TrainingHubHeader);
Vue.component('cms-header-dark-overhang', CmsHeaderDarkOverhang);
Vue.component('cms-problems-solutions-cards', CmsProblemsSolutionsCards);
Vue.component('cms-lottie-animation', CmsLottieAnimation);
Vue.component('cro-video-teaser', CroVideoTeaser);
Vue.component('cro-whitespace', CroWhitespace);
Vue.component('cro-icon-trio', CroIconTrio);
Vue.component('cms-blog-raw-content', CmsBlogRawContent);
Vue.component('cms-cta-light', CmsCtaLight);
Vue.component('cms-lever-dropdown', CmsLeverDropdown);
Vue.component('cms-insights-header', CmsInsightsHeader);
Vue.component('cms-logo-divider', CmsLogoDivider);
Vue.component('cms-header-with-carousel-quotes', CmsHeaderWithCarouselQuotes);
Vue.component('cms-colour-heading-cards', CmsColourHeadingCards);
Vue.component('cms-cards-4-cols-icons-links', CmsCards4ColsIconsLinks);
Vue.component('cms-2-cols-img-copy', Cms2ColsImgCopy);
Vue.component('cms-full-width-mobile-image', CmsFullWidthMobileImage);
Vue.component('cms-full-width-image', CmsFullWidthImage);
Vue.component('cms-quote-with-image', CmsQuoteWithImage);
Vue.component('cms-header-animation', CmsHeaderAnimation);
Vue.component('cms-pricing-on-page', CmsPricingOnPage);
Vue.component('cms-trust-with-numbers', CmsTrustWithNumbers);
Vue.component('cms-signup-form', CmsSignupForm);
Vue.component('cms-round-cards-links', CmsRoundCardsLinks);
Vue.component('cms-column-grouped-cards', CmsColumnGroupedCards);
Vue.component('cms-social-links', CmsSocialLinks);
Vue.component('cms-accordion-2-col', CmsAccordion2Col);
Vue.component('cms-tab-copy-media', CmsTabCopyMedia);
Vue.component('cms-cards-4-col-links', CmsCards4ColLinks);
Vue.component(
  'cms-card-landscape-rounded-corners',
  CmsCardLandscapeRoundedCorners
);
Vue.component('cms-card-outline-squiggle', CmsCardOutlineSquiggle);
Vue.component(
  'cms-integrations-filter-carousel',
  CmsIntegrationsFilterCarousel
);
Vue.component('cms-card-highlight-price', CmsCardHighlightPrice);
Vue.component('cms-personalisation', CmsPersonalisation);
Vue.component('cms-trust-carousel-quote', CmsTrustCarouselQuote);
Vue.component('cms-pricing-features', CmsPricingFeatures);
Vue.component('cms-step-by-step-2-col', CmsStepByStep2Col);
Vue.component('cms-award-badges', CmsAwardBadges);
Vue.component('cms-illustration-copy-link', CmsIllustrationCopyLink);
Vue.component('cms-card-to-modal-target', CmsCardToModalTarget);
Vue.component('blog-exit-pop-up-modal', BlogExitPopUpModal);
Vue.component('cms-press-cards', CmsPressCards);
Vue.component('cms-media-on-page', CmsMediaOnPage);
Vue.component('cms-testimonial-with-faq', CmsTestimonialWithFaq);
Vue.component('cms-marketo-media-form', CmsMarketoMediaForm);
Vue.component('cms-marketo-form', CmsMarketoForm);
Vue.component('signup-form', SignupForm);
Vue.component('book-a-consultation', BookAConsultation);
Vue.component('template-blank', templateBlank);
Vue.component('widget-signup-form', WidgetSignupForm);
Vue.component('cms-header-media-highlight', CmsHeaderMediaHighlight);
Vue.component('cms-login-carousel', CmsLoginCarousel);
Vue.component('cms-iframe-preview', CmsIframePreview);
Vue.component('cms-light-bg-testimonial', CmsLightBgTestimonial);
Vue.component('cms-error', CmsError);
Vue.component('cms-onpage-list', CmsOnpageList);
Vue.component('general-exit-pop-up-modal', GeneralExitPopUpModal);
Vue.component('cms-header-media-ctas', CmsHeaderMediaCtas);
Vue.component('cms-onpage-form-highlight', CmsOnpageFormHighlight);
Vue.component('cms-table', CmsTable);
Vue.component('modal-newsletter', ModalNewsletter);
Vue.component('cms-card-inline', CmsCardInline);
Vue.component('cms-company-rating', CmsCompanyRating);
Vue.component('cms-trust-with-workplaces', CmsTrustWithWorkplaces);
Vue.component('cms-2-cols-scroll', Cms2ColsScroll);
Vue.component('cms-button-v2', CmsButtonV2);
Vue.component('glossary-terms', GlossaryTerms);
Vue.component('cms-lazy-image', CmsLazyImage);
Vue.component('cms-trust-radius-widget', CmsTrustRadiusWidget);
Vue.component('cms-tag', CmsTag);
Vue.component('cms-add-on-pricing', CmsAddOnPricing);
Vue.component('cms-full-width-media', CmsFullWidthMedia);
Vue.component('cms-stats-3-cols', CmsStats3Cols);
Vue.component('cms-full-width-quote', CmsFullWidthQuote);
Vue.component('cms-full-width-carousel', CmsFullWidthCarousel);
Vue.component('cms-comparison-2-cols', CmsComparison2Cols);
Vue.component('cms-card-law', CmsCardLaw);
Vue.component('cms-disclaimer', CmsDisclaimer);
Vue.component('cms-state-list', CmsStateList);
Vue.component('cms-header-searchbox', CmsHeaderSearchBox);
Vue.component('cms-newsletter-open-modal', CmsNewsletterOpenModal);
